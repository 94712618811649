.popup {
  padding: 0 !important;
}

.botonL {
  padding: 20px;
  border-radius: 10px 10px 0px 0px;
  color: #fff;
  margin-right: 10px;
  cursor: pointer;
  width: 8vw;
}

.borderL {
  border-radius: 10px 10px 0 0 !important;
  border-bottom: 3px solid #1ac6c1 !important;
}

.borderM {
  border-radius: 10px 10px 0 0 !important;
  border-bottom: 3px solid #ea1877 !important;
}

.borderMI {
  border-radius: 10px 10px 0 0 !important;
  border-bottom: 3px solid #7b37db !important;
}

.borderJ {
  border-radius: 10px 10px 0 0 !important;
  border-bottom: 3px solid #d625b4 !important;
}

.borderV {
  border-radius: 10px 10px 0 0 !important;
  border-bottom: 3px solid #1289db !important;
}

.borderS {
  border-radius: 10px 10px 0 0 !important;
  border-bottom: 3px solid #24bde0 !important;
}

.contenedorImgImagenologia {
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: row;
  width: 33%;
  float: left;
}

.botonesAcciones {
  width: 3vw;
  margin: 10px;
  z-index: 0;
}

.contenedorImgVideoLlamada {
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: row;
  float: left;
}

.botonesAccionesVideoLlamada {
  width: 3vw;
  margin: 5px;
  z-index: 0;
}
