.btnOF {
  background-color: #0074d9;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  padding: 7px 16px;
  text-align: center;
  text-decoration: none;
  transition: 0.3s;
}

.btnOFActive {
  background-color: #024987;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  padding: 7px 16px;
  text-align: center;
  text-decoration: none;
  transition: 0.3s;
}

.divFechas div svg {
  color: orange;
}

.selecciones {
  color: rgb(128, 128, 128);
}

.inputCalendario {
  display: flex;
  background: transparent;
  width: 10rem;
  margin-left: 12px;
  border-radius: 4px;
  border: 2px solid orange;
  font-size: 12.5px;
  position: relative;
  height: 2.13rem;
  top: 8px;
  color: rgb(128, 128, 128);
  padding-top: 1px;
  padding-left: 5px;
}

.inputCalendario::-webkit-calendar-picker-indicator {
  cursor: pointer;
  margin-left: 12px;
  filter: invert(0.9);
}

.image {
  width: 17px;
  position: absolute;
  z-index: 1;
  right: 5%;
  top: 50%;
  pointer-events: none;
}

.inputCalendarioMes {
  display: flex;
  background: transparent;
  width: 10rem;
  margin-left: 12px;
  border-radius: 4px;
  border: 2px solid orange;
  font-size: 12.5px;
  position: relative;
  height: 2.13rem;
  top: 8px;
  color: rgb(128, 128, 128);
  padding-top: 1px;
  padding-left: 5px;
}

.inputCalendarioMes::-webkit-calendar-picker-indicator {
  cursor: pointer;
  filter: invert(0.9);
}
