.liMenu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 9px;

  transition: right 1.3s;
}

.liMenu button {
  border-bottom: 1px solid white;
  border-radius: 3px;
  color: white;
  margin-bottom: 2px;
  background-color: #09a6d1;
  height: 24px;
  width: 169px;
  font-size: 13px;
  cursor: pointer;
}

.liMenu button:hover {
  background-color: #03718f;
}

.liMenu2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 9px;
}

.liMenu2 button {
  border-bottom: 1px solid white;
  border-radius: 3px;
  color: white;
  margin-bottom: 2px;
  background-color: #09a6d1;
  height: 24px;
  width: 169px;
  font-size: 13px;
}

.liMenu2 button:hover {
  background-color: #03718f;
}

@media (max-width: 1450px) {
  .liMenu {
    margin-right: 124px;
  }

  .liMenu2 {
    margin-right: 124px;
  }
}

@media (max-height: 550px) {
  .liMenu {
    margin-right: 124px;
  }

  .liMenu2 {
    margin-right: 124px;
  }
}
