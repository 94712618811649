.reservas_externas_menu {
  background-color: #eaa028;
  border-radius: 15px;
  margin-top: 50px;
  margin-left: 10%;
  margin-right: 10%;
  text-align: center;
  height: 150px;
  padding: 35px;
  cursor: pointer;
}

.reservas_externas_boton {
  background-color: #eaa028;
  border: none;
  color: white;
  padding: 5px;
  font-weight: 800;
  border-radius: 5px;
  cursor: pointer;
  min-width: 90px;
  margin-bottom: 10px;
}

.reservas_externas_menu:hover {
  /*border: #5f3e09 solid 2px;*/
}

.reservas_externas_menu_label {
  color: white;
  font-size: 30px;
  font-weight: bold;
  cursor: pointer;
}

.pestaniaMenu {
  margin-left: 5px;
  background-color: #eaa028;
  padding: 20px 10px 20px 10px;
  width: 100px;
  color: #fff;
  font-weight: bold;
  border-radius: 10px 10px 0 0;
  cursor: pointer;
}

.recordatoriosExternosResolucion .MuiDialog-paperWidthSm {
  max-width: inherit;
  width: 40% !important;
}
.recordatoriosExternosObservaciones .MuiDialog-paperWidthSm {
  max-width: inherit;
  width: 60% !important;
}
.recordatoriosExternosDetalle .MuiDialog-paperWidthSm {
  max-width: inherit;
  width: 60% !important;
}
