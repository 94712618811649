.login {
  position: fixed;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-template-rows: 4fr 1fr;
  grid-template-areas:
    ". login-inicio ."
    ". . .";
  background-image: url("./../img/fondo.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.login-inicio {
  position: relative;
  grid-area: login-inicio;

  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  grid-template-rows: 1fr 2fr;
  grid-template-areas:
    ". login-logo ."
    ". login-cuerpo .";
}

.login-logo {
  grid-area: login-logo;
  position: relative;
  margin-top: 40px;
  background-image: url("./../img/logo_v2.svg");
  background-repeat: no-repeat;
  background-position: center;
  z-index: 20;
}

.login-cuerpo {
  grid-area: login-cuerpo;
  position: relative;
  margin-top: 40px;
  z-index: 20;
}

.login-cuerpo span {
  margin: 0;
}

.login-cuerpo .MuiTextField-root {
  margin: auto;
  width: 90%;
  margin: 0 5%;
  margin-bottom: 30px;
}

.login-cuerpo label + .MuiInput-formControl {
  margin-top: 16px;
  color: #234e65;
}

.login-cuerpo .MuiButton-root {
  margin-left: calc(50% - 50px);
  width: 100px;
  margin-top: 30px;
  background-color: #eaa028;
}

.login-cuerpo .MuiInput-underline:before {
  border-bottom: 3px solid rgb(255 255 255);
}

.login-cuerpo .MuiInput-underline:after {
  border-bottom: 3px solid rgb(255 255 255);
}

.login-cuerpo .MuiFormLabel-root {
  color: rgb(255 255 255);
}

.login-cuerpo .MuiFormLabel-root.Mui-focused {
  color: rgb(255 255 255);
}

.login-cuerpo .MuiButton-containedPrimary:hover {
  background-color: #ca8619;
}

.login-cuerpo .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 2px solid rgb(105 179 218);
}

#login-usuario,
#login-contrasena {
  color: #fff;
  text-align: center;
}

#login-usuario::-webkit-input-placeholder {
  color: red;
  text-align: center;
  padding-top: 30px;
}

#codigoRe,
#rutRe,
#claveNuevaRe,
#claveConfirmacionRe {
  color: #fff;
  text-align: center;
}

@media screen and (max-width: 80em) {
  .login-inicio {
    background-size: cover !important;
  }
}

.login-cuerpo .MuiInputLabel-shrink {
  transform-origin: top center !important;
}

.login-cuerpo .MuiFormLabel-root {
  width: 100% !important;
  text-align: center !important;
  transform-origin: top center !important;
  color: rgb(255 255 255 / 50%) !important;
}

.olvidasteClave {
  color: #ffffff;
  font-size: 1.2vw;
  cursor: pointer;
  margin-top: 2vw;
}

.login_salud {
  position: fixed;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-template-rows: 4fr 1fr;
  grid-template-areas:
    ". login-inicio ."
    ". . .";
  background-image: url("./../img/salud_linea.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.login_departamento {
  position: fixed;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-template-rows: 4fr 1fr;
  grid-template-areas:
    ". login-inicio ."
    ". . .";
  background-image: url("./../img/departamento_social.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.login_medico {
  position: fixed;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-template-rows: 4fr 1fr;
  grid-template-areas:
    ". login-inicio ."
    ". . .";
  background-image: url("./../img/fondo_medico.png");
  background-repeat: no-repeat;
  background-size: cover;
}
