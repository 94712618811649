.YearPicker {
    background: linear-gradient(rgb(224, 131, 20), rgb(234, 160, 40), rgb(252, 185, 96));
    color: white;
    z-index: 2;
    margin-left: 7px;
    width: 12rem;
    height: 2rem;
    border-radius: 5px;
    padding: 10px;
    
}

.YearPicker::placeholder{
    color:white
}

.mitabla {
    border-collapse: separate !important;
    border: 3px solid #eaa028;
    border-radius: 15px;
    width: 90%;
    height: 70%;
   
}

.mitabla thead th {
    border-bottom: 3px solid #eaa028;
    padding: 1%;
    text-align: center;
    width: 20%;
}

.mitabla tbody td {
    padding: 1%;
    text-align: center;
}

.modal {
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.modalcontent {
    position: absolute;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 70%;
    height: 70%;
        display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: flex-start;
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
}


.mitabla2 {
    border-collapse: separate !important;
    border: 3px solid #eaa028;
    border-radius: 15px;
    width: 70%;
    display:"flex";

}

.mitabla2 thead th{
    border-bottom: 3px solid #eaa028;
    padding: 1%;
    text-align: center;
}

.mitabla2 tbody td {
    padding: 1%;
    text-align: center;
}

.divTabla{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2%;
}

.divUpperTabla{
    display: flex;
    flex-direction: column;
    width: 70%;
    height: 100%;
    align-items: flex-start;
}


.divBotones button {
    width: 18.6%;
    height: 60%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0.5%;
}       

.listaFamilia {
    position: absolute;
        background: linear-gradient(rgb(224, 131, 20), rgb(234, 160, 40), rgb(252, 185, 96));
        width: 12.6%;
        z-index: 2;
        display: flex;
        flex-direction: column;
        border-radius: 7px;
        left: 0.8rem;
        top: 7%;
}
.listaAtencion {
    position: absolute;
        background: linear-gradient(rgb(221, 31, 149), rgb(239, 69, 151), rgb(255, 125, 193));
        width: 12.6%;
        z-index: 2;
        display: flex;
        flex-direction: column;
        border-radius: 7px;
        left: 14.7%;
        top: 7%;
}
.listaSector {
    position: absolute;
        background: linear-gradient(rgb(31, 163, 137), rgb(48, 181, 155), rgb(93, 209, 189));
        width: 12.6%;
        z-index: 2;
        display: flex;
        flex-direction: column;
        border-radius: 7px;
        left: 28.5%;
        top: 7%;
}
.listaProfesional {
    position: absolute;
    background: linear-gradient(rgb(20, 50, 117), rgb(34, 80, 147), rgb(54, 117, 193));
    width: 10.4rem;
    z-index: 2;
    display: flex;
    flex-direction: column;
    border-radius: 7px;
    left: 42%;
    top: 3rem;
}

.buttonFamilia{
    margin: 1px 0px 1px 12px;
    background: linear-gradient(rgb(224, 131, 20), rgb(234, 160, 40), rgb(252, 185, 96));
    border-radius: 10px;
    color: white;
    cursor: pointer;
}

.buttonAtencion {
    margin: 1px 0px 1px 12px;
    background: linear-gradient(rgb(221, 31, 149), rgb(239, 69, 151), rgb(255, 125, 193));
    border-radius: 10px;
    color: white;
    cursor: pointer;
}

.buttonSector {
    margin: 1px 0px 1px 12px;
    background: linear-gradient(rgb(31, 163, 137), rgb(48, 181, 155), rgb(93, 209, 189));
    border-radius: 10px;
    color: white;
    cursor: pointer;
}

.buttonProfesional {
    margin: 1px 0px 1px 12px;
    background: linear-gradient(rgb(20, 50, 117), rgb(34, 80, 147), rgb(54, 117, 193));
    border-radius: 10px;
    color: white;
    cursor: pointer;
}

.divBotones{
    width: 100%;
    height: 50%;
    display: flex;
}

.divFechas{
    display: flex;
    flex-direction: row;
    height: 2rem;
}


/* .divFechas input {
    text-align: center;
    background: transparent;
    color: rgb(128, 128, 128);
    border-top: none;
    border-right: none;
    border-bottom: 2px solid black;
    border-left: none;
    border-image: initial;
    width: 10.3rem;
    height: 1.5rem;
    margin-left: 12px;
    border-radius: 0px;
    font-size: 0.8vw;
} */

.divSemana{
    display: flex;
    flex-direction: column;
}

.divSemana label{
    margin-bottom: 2.8%;
}

.divTabla2 {
    height: 100%;
    overflow: auto;
}

.divTabla2 thead tr th {
    width: 20%;
}

.divTabla2 tbody tr td {
    padding: 1%;
}

.divTabla2 table {
    width: 100%;
    background-color:#e8ecef;
    border-collapse: collapse;
    border: 3px solid #eaa028;
    border-radius: 15px;
    display: block;

}

.divTabla2 table thead,
.divTabla2 table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.divTabla2 tbody {
    display: block;
    height: 295px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.divTabla2 thead {
    display: table;
    width: calc(100% - 4px);
    table-layout: fixed;
    border-bottom: 3px solid #eaa028;
    height: 35px;
}

.divTabla2 thead th,
.divTabla2 tbody tr td {
    text-align: center;
}

.btnLimpiar {
    font-size: 80%;
    margin-left: 1%;
    background: orange !important;
    cursor: pointer;
    border-radius: 10px;
    height: 2rem;
    width: 8%;

}

.divBtnLimpiar {
    display: flex;
    align-items: flex-end;
    margin-left: 12px;

}

.inputTable{
    height: 2rem;
    width: 13rem;
    border: solid 3px #eaa028;
    border-radius: 10px;
    padding-left: 4%;
    background-color: transparent;

}

.divTitulo {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.sinDatos{
    display: flex;
    height: 295px;
    align-items: center;
    justify-content: center;
}

.btnLimpiarSectores{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(to bottom, #6e45af, #8156c1, #9b76e2);
    color: white;
    border: none;
    width: 10rem;
    height: 2rem;
    padding: 0 1rem;
    font-size: 0.9rem;
    margin-left: 7px;
    border-radius: 5px;
}

.tablaGrid{
    height: 100%;
    overflow: auto;
    display: flex;
}

.tablaGrid table {
    background-color: #e8ecef;
    border-collapse: collapse;
    border: 3px solid #79c3e8;
    border-radius: 0px 0px 0px 0px;
    display: block;
}

.tablaGrid th,
td {
    padding: 8px;
    text-align: center;
    
}

.tablaGrid thead {
    border-bottom: 3px solid #79c3e8;
    height: 35px;
}
.tablaGrid label{
    transform: rotate(-90deg);
}

.trBottom{
    border-bottom: 3px solid #79c3e8;
}

.trBottom0 {
    border-bottom: 3px solid #eaa028;
}
.trBottom1{
border-bottom: 3px solid #57c4b7;   
}

.detalles{
    transform: rotate(-90deg);
    margin-right: -3.5rem !important;
    width: 9.4rem;
    border-radius: 15px 15px 0px 0px !important;
    background-color: #eaa028 !important;
    color: white !important;
    border: 3px solid #eaa028 !important;
}

.grid1{
    display: flex;
    align-items: center;
    position: relative;
}

.detalles1 {
    transform: rotate(-90deg);
    margin-right: -5.7rem !important;
    width: 14rem;
    border-radius: 15px 15px 0px 0px !important;
    background-color: #79c3e8 !important;
    color: white !important;
    border: 3px solid #79c3e8 !important;
    margin-left: -2.2rem !important;
}
.detalles2 {
    transform: rotate(-90deg);
    margin-right: -3.4rem !important;
    width: 9.4rem;
    border-radius: 15px 15px 0px 0px !important;
    background-color: #57c4b7 !important;
    color: white !important;
    border: 3px solid #57c4b7 !important;
    margin-left: -0.2rem !important;
}


.grafico1{
    position: absolute;
    width: 48rem;
    height: 100%;
    left: 22.3rem;
    border: 3px solid #eaa028;
    border-radius: 0px 15px 15px 0px !important;
}

.graficoCall{
    width: 90%;
    height: 22rem;
    left: 2%;
    position: absolute;
}

.graficoCall1{
    width: 90%;
    height: 22rem;
}

.graficoCall2{
    width: 60%;
    height: 22rem;
    margin-left: 1%;
}




.imgRecoleta{
    width: 120px;       
    height: 170px;   
    margin-right: 20px;    
    background-image: url('../../assets/img/graficoCall/recoleta.svg');
    background-repeat: no-repeat; 
    cursor: pointer;
}
.imgCristoVive{
    width: 120px;       
    height: 170px;   
    margin-right: 20px;    
    background-image: url('../../assets/img/graficoCall/cristovive.svg');
    background-repeat: no-repeat; 
    cursor: pointer;
}
.imgMacul{
    width: 120px;       
    height: 170px;   
    margin-right: 20px;    
    background-image: url('../../assets/img/graficoCall/macul.svg');
    background-repeat: no-repeat; 
    cursor: pointer;
}
.imgPenaflor{
    width: 120px;       
    height: 170px;   
    margin-right: 20px;    
    background-image: url('../../assets/img/graficoCall/penaflor.svg');
    background-repeat: no-repeat; 
    cursor: pointer;
}
.imgNuevaImperial{
    width: 120px;       
    height: 170px;   
    margin-right: 20px;    
    background-image: url('../../assets/img/graficoCall/nuevaimperial.svg');
    background-repeat: no-repeat; 
    cursor: pointer;
}
.imgAntofa{
    width: 120px;       
    height: 170px;   
    margin-right: 20px;    
    background-image: url('../../assets/img/graficoCall/antofagasta.svg');
    background-repeat: no-repeat; 
    cursor: pointer;
}
.imgPurranque{
    width: 120px;       
    height: 170px;   
    margin-right: 20px;    
    background-image: url('../../assets/img/graficoCall/purranque.svg');
    background-repeat: no-repeat; 
    cursor: pointer;
}
.imgFrutillar{
    width: 120px;       
    height: 170px;   
    margin-right: 20px;    
    background-image: url('../../assets/img/graficoCall/frutillar.svg');
    background-repeat: no-repeat;
    cursor: pointer; 
}
.imgPuyehue{
    width: 120px;       
    height: 170px;   
    margin-right: 20px;    
    background-image: url('../../assets/img/graficoCall/puyehue.svg');
    background-repeat: no-repeat; 
    cursor: pointer;
}
.imgPelluhue{
    width: 120px;       
    height: 170px;   
    margin-right: 20px;    
    background-image: url('../../assets/img/graficoCall/pelluhue.svg');
    background-repeat: no-repeat; 
    cursor: pointer;
}
.imgLampa{
    width: 120px;       
    height: 170px;   
    margin-right: 20px;    
    background-image: url('../../assets/img/graficoCall/lampa.svg');
    background-repeat: no-repeat; 
    cursor: pointer;
}
.imgCerroNavia{
    width: 120px;       
    height: 170px;   
    margin-right: 20px;    
    background-image: url('../../assets/img/graficoCall/cerronavia.svg');
    background-repeat: no-repeat; 
    cursor: pointer;
}
.imgIzquierda
{
    width: 20px;       
    height: 60px;   
    margin-right: 20px;   
    background-image: url('../../assets/img/graficoCall/flecha.svg');
    background-repeat: no-repeat; 
    transform: scaleX(-1);
    cursor: pointer;
}

.imgDerecha
{
    width: 20px;       
    height: 60px;   
    background-image: url('../../assets/img/graficoCall/flecha.svg');
    background-repeat: no-repeat; 
    cursor: pointer;
}

.labelCallNumeros{
    color: white;
    font-weight: bold;
}
.labelCallLetras{
    color: white;
}

.divRegiones{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 18px;
}

.transparente {
    opacity: 0.3; 

  }

.btnNaranjosCall{
    background: #eaa028 !important;
    color: white !important;
    border: none;
    width: 7vw;
    height: 2rem;
    padding: 0 1rem;
    font-size: 0.8rem !important;
    margin-left: 7px;
    border-radius: 5px;
    line-height: 1 !important;
    margin-right: 1vw !important;

}

.active{
    background: #a77422 !important;
    color: white !important;
    border: none;
    width: 7vw;
    height: 2rem;
    padding: 0 1rem;
    font-size: 0.8rem !important;
    margin-left: 7px;
    border-radius: 5px;
    line-height: 1 !important;
    margin-right: 1vw !important;
}

.inputTypeWeek {
    margin-top: 1vh;
    height: 28px;
    margin-left: 0.5vw;
    border: 1px solid black;
    border-radius: 4px;
    color: rgb(128, 128, 128); 
    width: 10vw;
    padding-left: 0.5vw;
    padding-right: 0.5vw;
    font-size: 0.7rem;
    background: none;
    appearance: none; 
}

.divSemanaCall input[type="week"]::-webkit-calendar-picker-indicator {
    background: none;
}
.divMesCall input[type="month"]::-webkit-calendar-picker-indicator {
    background: none;
}
.grafico2 {
    position: absolute;
    width: 48rem;
    height: 100%;
    left: 22.5rem;
    border: 3px solid #79c3e8;
    border-radius: 0px 15px 15px 0px !important;
}


.grafico3 {
    position: absolute;
    width: 47rem;
    height: 100%;
    left: 23.4rem;
    border: 3px solid #57c4b7;
    border-radius: 0px 15px 15px 0px !important;
}

.modal3 {
    z-index: 45;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.modalcontent2 {
    position: absolute;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #f4f4f4;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 70%;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
}


.tablaModalGrid{
    position: absolute;
    padding: 2%;
    top: 23%;
    
}

.buscadorEspecialidad{
    position: absolute;
    top: 18%;
    width: 95%;
    left: 3.7%;
}

.tablaModalGrid table{
    height: 100%;
    overflow: auto;
    width: 100%;
    background-color: #f4f4f4;
    border-collapse: collapse;
    border: 3px solid #eaa028;
    border-radius: 15px;
    display: block;
}

.tablaModalGrid table thead,
.tablaModalGrid table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.tablaModalGrid tbody {
    display: block;
    height: 295px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.tablaModalGrid thead {
    border-bottom: 3px solid #eaa028;
    height:2.3rem ;
}

.tablaModalGrid tbody tr {
    border-bottom: 3px solid #eaa028;
}


.tablaGrid1 {
    height: 100%;
    overflow: auto;
    display: flex;
}

.tablaGrid1 table {
    background-color: #e8ecef;
    border-collapse: collapse;
    border: 3px solid #57c4b7;
    border-radius: 0px 0px 0px 0px;
    display: block;
}

.tablaGrid1 th,
td {
    padding: 8px;
    text-align: center;

}

.tablaGrid1 thead {
    border-bottom: 3px solid #57c4b7;
    height: 35px;
}


.tablaGrid1 label {
    transform: rotate(-90deg);
}


.tablaGrid0 {
    height: 100%;
    overflow: auto;
    display: flex;
}

.tablaGrid0 table {
    background-color: #e8ecef;
    border-collapse: collapse;
    border: 3px solid #eaa028;
    border-radius: 0px 0px 0px 0px;
    display: block;
}

.tablaGrid0 th,
td {
    padding: 8px;
    text-align: center;

}


.tablaGrid0 thead {
    border-bottom: 3px solid #eaa028;
    height: 35px;
}


.tablaGrid0 label {
    transform: rotate(-90deg);
}


.inputfilter{
    position: absolute;
    top: 18%;
    left: 4%;
    height: 2rem;
    width: 13rem;
    border: solid 3px #eaa028;
    border-radius: 10px;
    padding-left: 2%;
    background-color: transparent;
}

.divImagenologia{
    margin-top: 40px;
    border: 2px solid #eaa028;
    margin-left: 43px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    
}

.divImagenologia label{
    transition: 1s;
}

.divImagenologia img{
    transition: 1s;
}