.buscador {
  width: 40%;
  margin-right: 30px;
  background: transparent;
  border: solid 3px #e99f28;
  border-radius: 5px;

  padding: 15px;
  font-size: 18px;
  height: 50px;
}
.btnAcciones {
  float: left;
  color: #fff;
  padding: 9px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  margin-right: 20px;
  border-bottom: none !important;
  background-color: #eaa028 !important;
  font-size: 1.2rem;
}

.btnAcciones2 {
  float: left;
  color: #fff;
  padding: 9px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  margin-right: 20px;
  border-bottom: none;
  font-size: 1.2rem;
}

.botonRevisatTicket {
  float: left;
  color: #fff;
  padding: 9px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  margin-right: 20px;
  border-bottom: none !important;
  background-color: #eaa028 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22%;
  height: 50px;
  font-size: 1.3vw;
}
.botonIngresarSolicitud {
  float: left;
  color: #fff;
  padding: 9px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  margin-right: 20px;
  border-bottom: none !important;
  background-color: #eaa028 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
  height: 50px;
  font-size: 1.3vw;
}

.contenedorFormIngreso {
  display: flex;
  margin-top: 2% !important;
  width: 100% !important;
}

.botonModal {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  width: 180px;
  margin-top: 5%;
  background-color: rgb(57, 182, 74);
  float: left;
  color: #fff;
  padding: 9px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  margin-right: 20px;
}

.inputIngreso {
  padding: 10px;
  border-radius: 5px;
  border: 3px solid #efb65e !important;
  background: none;
  font-size: 1vw;
  width: 95%;
  height: 100%;
}

textarea {
  font-size: 1vw !important;
}

.selectoresIngreso {
  background: none;
  font-size: 1rem;
  width: 90%;
  border-radius: 5px;
  margin: 0 !important;
  border: 3px solid #efb65e !important;
}
.inputError {
  border: solid 3px #c1272d !important;
}
.selectores path {
  fill: #eaa028;
}

/* VISUAL TICKET */

.barra {
  width: 100%;
  height: 20px;
  background: #99c5ec;
  clip-path: polygon(10% 0, 100% 1%, 90% 100%, 0% 100%);
}

.contenedorBarra_pasado {
  width: 20%;
  text-align: center;
}
.contenedorBarra_pasado img {
  width: 40%;
  margin-top: 20px;
}

.contenedorBarra_pasado img,
.contenedorBarra_pasado p {
  margin-top: 15px;
  opacity: 0.4;
}
.contenedorBarra p {
  margin-top: 15px;
  font-size: 1.2rem;
}

.barra_gris {
  width: 100%;
  height: 20px;
  background: #cccccc;
  clip-path: polygon(10% 0, 100% 1%, 90% 100%, 0% 100%);
}

.contenedorBarra {
  width: 20%;
  text-align: center;
  opacity: 1;
}
.contenedorBarra img {
  width: 45%;
  margin-top: 20px;
}
.contenedorBarra p {
  margin-top: 15px;
}

.contendorInfo {
  width: 85%;
  border: 3px solid #eaa028;
  display: flex;
  margin-top: 3%;
  padding: 35px;
  border-radius: 10px;
}

.contendorInfo h5 {
  color: #4d4d4d;
  font-size: 1rem;
  margin-bottom: 15px;
}

.contendorInfo span {
  font-weight: 100;
}

.revisarEstado {
  position: relative;
  float: right;
  z-index: 9;
  height: 45px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: #fff;
  padding: 9px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  background-color: #1babdd !important;
}

.btnNaranja {
  position: relative;
  float: right;
  z-index: 9;
  height: 45px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: #fff;
  padding: 9px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  background-color: #eaa028 !important;
}

.btnSi_No_notificacion {
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eaa028;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.modalSolicitudTrabajo .MuiDialog-paperWidthSm {
  background-image: url(./../img/marca_de_agua20.svg);
  background-repeat: no-repeat;
  background-position: right bottom;
  width: 1400px;
}

.campoIncompleto {
  border-bottom: solid 1px #c1272d;
  padding: 10px;
  background: none;
  font-size: 1rem;
  width: 95%;
}
.popapSolicitudTrabajo {
  position: fixed;
  background: white;
  z-index: 45;
  top: -100%;
  right: 25%;
  width: 50%;
  height: 14%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: top 2s ease;
  border: solid #c61714 6px;
  border-radius: 15px;
}

.popapSolicitudTrabajo {
  -webkit-animation: mover 2s infinite alternate;
  animation: mover 2s infinite alternate;
}
.popapSolicitudTrabajo {
  -webkit-animation: mover 2s infinite alternate;
  animation: mover 2s infinite alternate;
}
@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

.h1Modal {
  margin-bottom: 20px;
  font-size: 1.4rem;
  color: #4f4f4f;
}

.p1Modal {
  font-size: 1rem;
  font-weight: 100;
}

.btnModal {
  color: white;
  width: 100px;
  margin: 40px;
  height: 36px;
  background-color: #eaa028;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.textAreaMotivoRechazo {
  height: 80px;
  background: transparent;
  border: solid 1px;
  padding: 5px;
  border-radius: 10px;
  border-bottom-right-radius: 0px;
  margin-left: 10px;
}

.showErr {
  border: solid 1px red;
}

.spanError {
  text-align: center;
  width: 70%;
  color: red;
}

.contenedorFechasSolicitud {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.contenedorBotones {
  display: flex;
  margin: 0% 0% 1% 3%;
  width: 100%;
}
.botonesIconos {
  width: 6%;
  margin-right: 1%;
  position: relative;
  cursor: pointer;
}
.botonesIconosPrincipal {
  width: 6.8%;
  margin-right: 1%;
  position: relative;
  cursor: pointer;
}
.botonesIconosSelected {
  width: 6.5%;
}
.botonesIconosPrincipalSelected {
  width: 7.5%;
}
.botonesSeleccion {
  width: 9%;
  height: 2.3vw;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: white;
  background: #f8bb60;
  font-size: 0.9vw;
  margin-right: 1%;
  cursor: pointer;
  position: relative;
}

.botonSelected {
  background: #e99f28;
}

/* Modal Solicitud */
.labelInput {
  font-size: 0.7vw;
  color: #515151;
}
.paddingGrid {
  padding: 0 10px;
}

.inputModalSolicitud {
  width: 100%;
  height: 35px;
  background: transparent;
  border: solid 3px #a2a2a2;
  padding: 0 5px;
  border-radius: 3px;
  color: #9a9a9b;
  font-size: 0.75vw;
  margin-top: 7px;
}
.textareaModalSolicitud {
  width: 100%;
  background: transparent;
  border: solid 3px #a2a2a2;
  padding: 0 5px;
  border-radius: 3px;
  color: #9a9a9b;
  resize: none;
  font-size: 0.75vw;
  height: 95px;
  padding-top: 10px;
  margin-top: 7px;
}

.botonesAccione {
  width: 8vw;
  font-size: 1vw;
  color: white;
  margin-right: 10px;
  padding: 6px;
  border-radius: 3px;
  height: 3rem;
}

.error {
  animation: shake 200ms;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}

.textAreaIngresado {
  width: 100% !important;
  height: 80px !important;
  padding: 10px;
  border: 3px solid #eaa028;
  border-radius: 5px;
  background: transparent;
  resize: none;
  font-size: 17px;
  color: #575757;
  padding-right: 40px;
}

.textAreaIngresado::-webkit-scrollbar {
  width: 12px; /* Ancho del scrollbar vertical */
}

.textAreaIngresado::-webkit-scrollbar-track {
  background: #fff; /* Color de fondo del track */
}

.textAreaIngresado::-webkit-scrollbar-thumb {
  background: orange; /* Color de la parte deslizante del scrollbar */
  border-radius: 6px; /* Bordes redondeados */
}

.textAreaIngresado::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color del thumb al pasar el mouse */
}

.tamanio_numero1 {
  font-size: 0.7vw;
  color: white;
  position: absolute;
  top: 4%;
  right: 21%;
}
.tamanio_numero2 {
  font-size: 0.7vw;
  color: white;
  position: absolute;
  top: 4%;
  right: 18%;
}

.tamanio_numero1_select {
  font-size: 0.8vw;
  color: white;
  position: absolute;
  top: 4%;
  right: 21%;
}
.tamanio_numero2_select {
  font-size: 0.8vw;
  color: white;
  position: absolute;
  top: 4%;
  right: 17%;
}

.checkboxInput {
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #808080;
  appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
}

.divBorderTableSolicitudCesfamPendiente div[class*="Component-horizontalScrollContainer"] table,
.divBorderTableSolicitudCesfamPendiente div[class*="jss"] table {
  border-collapse: separate !important;
  border: 4px solid #c641a7;
  border-radius: 10px;
}
.divBorderTableSolicitudCesfamEnviado div[class*="Component-horizontalScrollContainer"] table,
.divBorderTableSolicitudCesfamEnviado div[class*="jss"] table {
  border-collapse: separate !important;
  border: 4px solid #3fb8b7;
  border-radius: 10px;
}
.divBorderTableSolicitudCesfamRechazado div[class*="Component-horizontalScrollContainer"] table,
.divBorderTableSolicitudCesfamRechazado div[class*="jss"] table {
  border-collapse: separate !important;
  border: 4px solid #eaa029;
  border-radius: 10px;
}
.divBorderTableSolicitudCesfamTerminado div[class*="Component-horizontalScrollContainer"] table,
.divBorderTableSolicitudCesfamTerminado div[class*="jss"] table {
  border-collapse: separate !important;
  border: 4px solid #1bacdd;
  border-radius: 10px;
}

.tamanoCuentaRespuesta {
  position: absolute;
  color: white;
  top: 2%;
  right: 21%;
  font-size: 1vw;
}
.tamanoCuentaRespuesta2 {
  position: absolute;
  color: white;
  top: 2%;
  right: 22%;
  font-size: 0.8vw;
}

.customTooltip {
  background-color: black;
  color: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  font-size: 14px;
}

.spanHaySolicitud {
  position: absolute;
  top: -6%;
  right: -3%;
  background: #ce1717;
  border-radius: 50%;
  width: 0.8vw;
  height: 0.8vw;
}
