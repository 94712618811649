.funcionario_multi_selector .dropdown-container {
  background: transparent;
  border: none;
  border-bottom: solid 1px;
  border-radius: 0;
}

.funcionario_multi_selector
  .dropdown-container
  .dropdown-content
  .panel-content {
  position: fixed;
}
.multiselectDisabled span {
  color: #808080;
}
.DialogFuncionarios .MuiDialog-container .MuiDialog-paper {
  border-radius: 2em !important;
}

.DialogFuncionarios .MuiDialog-paperWidthSm {
  background-image: url("./../img/logo_opacidad_02.svg");
  background-repeat: no-repeat;
  background-position: right bottom;
}
