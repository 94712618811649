.flex-container {
  display: flex;
  flex-wrap: wrap;
}

.flex-container > div {
  width: 270px;
  margin: 10px;
  text-align: center;
  margin-top: 15px;
  font-size: 18px;
  margin-left: 70px;
  box-sizing: border-box;
}

.modal-ver-mas-titulo {
  text-align: start;
  font: "Roboto", "Helvetica", "Arial", sans-serif;
  padding-bottom: 40px;
  font-size: 20px;
  margin-left: 60px;
}

.modal-ver-mas-contador {
  color: #eaa028;
  border-radius: 50%;
  padding: 10px;
  width: 60px;
  height: 60px;
  line-height: 30px;
  float: left;
  border: 3px solid #eaa028;
  box-sizing: border-box;
  font: 30px "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: bold;
  flex: 1;
  cursor: pointer;
}

.modal-ver-mas-nombre {
  color: black;
  text-align: center;
  font: "Roboto", "Helvetica", "Arial", sans-serif;
  margin-top: 20px;
}

.boton-filtrar {
  background-color: #eaa028;
  width: 100px;
  height: 35px;
  cursor: pointer;
  margin-top: 30px;
  margin-left: 10px;
  border-radius: 5px;
  color: #ffffff;
  border: #ffffff;
}

.boton-cerrar {
  background-color: #eaa028;
  width: 100px;
  height: 35px;
  float: right;
  cursor: pointer;
  margin-top: 30px;
  margin-left: 10px;
  border-radius: 5px;
  color: #ffffff;
  border: #ffffff;
  margin-right: 70px;
}

.oferta-demanda {
  display: flex;
}

.oferta {
  flex: 1;
}

.demanda {
  flex: 1;
}

.lista-especialidades {
  margin-top: 20px;
  margin-left: 5px;
}

.filtros-oferta-demanda {
  display: flex;
}

.select-especialidad {
  background-color: #ffffff;
  text-align: center;
  font-size: 1vw;
  width: 260px;
  height: 35px;
  color: #000;
  border-radius: 5px;
  margin: 10px 0px;
  flex: 1;
  border: gray solid 1px;
}

.select-semana {
  background-color: #ffffff;
  text-align: center;
  color: black;
  font-size: 1vw;
  width: 260px;
  height: 35px;
  border-radius: 5px;
  margin: 25px;
  margin-top: 30px;
  flex: 1;
  border: gray solid 1px;
}
.nsp-container {
  display: contents;
}

.calendarios-nsp {
  height: 35px;
  border-radius: 5px;
  font: 15px "Roboto", "Helvetica", "Arial", sans-serif;
  text-align: center;
  margin-inline-end: 20px;
  border: gray solid 1px;
  cursor: pointer;
}

.btn-vista-nsp {
  border: none;
  cursor: pointer;
  background-color: transparent;
  transition: 0.5s all ease-in-out;
  text-align: left;
  margin-top: 7px;
  flex: 1;
}

.btn-vista-nsp:hover {
  transform: scale(1.5);
}
