.botonesAlineados {
  float: right !important;
  margin-right: 10px !important;
  box-shadow: none !important;
  color: #fff !important;
  width: auto !important;
}

.botonesDemanda {
  background-color: #fff !important;
  color: #69b3da !important;
}

.inputInicio {
  width: 50% !important;
  padding: 12px 20px !important;
  margin: 8px 0 !important;
  display: inline-block !important;
  border: 1px solid #7a7676 !important;
  border-radius: 4px !important;
  box-sizing: border-box !important;
}

.imagenUsuario {
  width: 40px;
  margin-left: 15px;
  margin-bottom: -10px;
}

.primeraImagen {
  margin-left: 50px;
}

.fichaUsuario {
  margin-top: 10px;
}

.middle-content .makeStyles-grid-1 {
  width: unset;
  margin: 0 0px !important;
}

.divForm input,
.divForm label {
  color: #000 !important;
}

.botonesAlineados {
  color: #fff !important;
}

.buscarUsuario .MuiPaper-root {
  position: initial !important;
}

.agendarHora .MuiDialog-paperWidthSm {
  width: 70% !important;
}

.buscarUsuario .MuiButton-contained,
.cargarUsuarios .MuiButton-textPrimary {
  box-shadow: none !important;
  color: #fff !important;
  text-transform: capitalize !important;
  margin-right: 10px;
  margin-left: 10px;
}

.modificarUsuario input,
.comentariosUsuario input,
.grupoFamiliarUsuario input {
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  height: 1.1876em;
  margin: 0;
  display: block;
  padding: 6px 0 7px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
  border-bottom: 1px solid #000;
}
.modificarUsuario input:focus,
.comentariosUsuario input:focus,
.grupoFamiliarUsuario input:focus {
  outline: none;
}

.bordeCarta {
  border: none !important;
  width: 100% !important;
}

.bordeCarta .MuiCardContent-root {
  border: 2px solid #eaa028;
  border-radius: 15px;
  margin-top: 10px;
}

.modificarUsuario .MuiButton-textPrimary {
  color: #fff !important;
  float: right !important;
  text-transform: capitalize !important;
}

.botonDialog {
  float: right !important;
  color: #fff !important;
  text-transform: capitalize !important;
}

.botonSimple {
  color: #fff !important;
  text-transform: capitalize !important;
}

.botonError {
  background-color: #bf5b5b !important;
  color: #fff;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 5px;
}

.buscarUsuario .MuiInput-underline:after {
  border-bottom: 2px solid #69b3da !important;
}

.buscarUsuario .MuiFormLabel-root.Mui-focused {
  color: #69b3da !important;
}

#preguntasDemanda .MuiDialog-paperWidthSm,
#preguntasDemanda .MuiDialog-paperWidthSm,
#finalizarDemanda .MuiDialog-paperWidthSm {
  background-image: url("./../img/marca_de_agua20.svg");
  background-repeat: no-repeat;
  background-position: right bottom;
}
