.miTabla {
    border: 2px solid #eaa028;
    border-collapse: collapse;
    width: 100%;
    border-radius: 10px;
  }
  
  .miTabla th,
  .miTabla td {
    border: 2px solid #eaa028;
    padding: 8px;
    
  }
  .miTablaWrapper {
    height: 50%; /* ajusta la altura según tus necesidades */
    overflow-y: scroll;
  }

  .miTabla td {
    text-align: center;
  }