::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */

  color: #808080;
  opacity: 1; /* Firefox */
}

.grafico_fecha .rmdp-calendar {
  background: linear-gradient(to bottom, #e08314, #eaa028, #fcb960);
}

.grafico_fecha1 .rmdp-calendar {
  background: linear-gradient(to bottom, #dd1f95, #ef4597, #ff7dc1);
}

.dateGraf .sd {
  color: white;
}

.dateGraf .rmdp-header-values {
  color: white;
}

.dateGraf .rmdp-day {
  color: white;
}

.Graficos .sd {
  color: white;
}

.Graficos .rmdp-header-values {
  color: white;
}

.Graficos .rmdp-day {
  color: white;
}

.Graficos .rmdp-week-day {
  color: white;
}
.dateGraf .rmdp-week-day {
  color: white;
}

.grafico_fecha .rmdp-week-day {
  color: white;
}

.grafico_fecha .rmdp-year-picker {
  background: linear-gradient(to bottom, #e08314, #eaa028, #fcb960);
}

.grafico_fecha .rmdp-month-picker {
  background: linear-gradient(to bottom, #e08314, #eaa028, #fcb960);
}

.grafico_fecha1 .rmdp-week-day {
  color: white;
}

.grafico_fecha1 .rmdp-year-picker {
  background: linear-gradient(to bottom, #dd1f95, #ef4597, #ff7dc1);
}

.grafico_fecha1 .rmdp-month-picker {
  background: linear-gradient(to bottom, #dd1f95, #ef4597, #ff7dc1);
}

.arrow-down-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(to bottom, #6e45af, #8156c1, #9b76e2);
  color: white;
  border: none;
  width: 12rem;
  height: 2rem;
  padding: 0 1rem;
  font-size: 0.9rem;
  margin-left: 7px;
  border-radius: 5px;
}

.arrow-down-button .button-text {
  flex: 1;
  text-align: center;
}

.arrow-down-button .arrow {
  margin-left: 0.5rem;
}

.arrow-down-button1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(to bottom, #143275, #225093, #3675c1);
  color: white;
  border: none;
  width: 12rem;
  height: 2rem;
  padding: 0 1rem;
  font-size: 0.9rem;
  border-radius: 5px;
  margin-left: 7px;
}

.arrow-down-button1 .button-text {
  flex: 1;
  text-align: center;
}

.arrow-down-button1 .arrow {
  margin-left: 0.5rem;
}

.arrow-down-button2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(to bottom, #09a6d1, #23bae8, #64ddf9);
  color: white;
  border: none;
  width: 12rem;
  height: 2rem;
  padding: 0 1rem;
  font-size: 0.9rem;
  border-radius: 5px;
  margin-left: 7px;
}

.arrow-down-button2 .button-text {
  flex: 1;
  text-align: center;
}

.arrow-down-button2 .arrow {
  margin-left: 0.5rem;
}

.arrow-down-button3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(to bottom, #40b56f, #63c189, #79d89f);
  color: white;
  border: none;
  width: 12rem;
  height: 2rem;
  padding: 0 1rem;
  font-size: 0.9rem;
  border-radius: 5px;
  margin-left: 7px;
}

.arrow-down-button3 .button-text {
  flex: 1;
  text-align: center;
}

.arrow-down-button3 .arrow {
  margin-left: 0.5rem;
}

.arrow-down-button4 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(to bottom, #d8275e, #f45175, #ff7b9e);
  color: white;
  border: none;
  width: 12rem;
  height: 2rem;
  padding: 0 1rem;
  font-size: 0.9rem;
  border-radius: 5px;
  margin-left: 7px;
}

.arrow-down-button4 .button-text {
  flex: 1;
  text-align: center;
}

.arrow-down-button4 .arrow {
  margin-left: 0.5rem;
}

.arrow-down-button5 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(to bottom, #d8b41d, #e8c42a, #f7d857);
  color: white;
  border: none;
  width: 12rem;
  height: 2rem;
  padding: 0 1rem;
  font-size: 0.9rem;
  margin-top: 0.5rem;
  border-radius: 5px;
  margin-left: 8px;
}

.arrow-down-button5 .button-text {
  flex: 1;
  text-align: center;
}

.arrow-down-button5 .arrow {
  margin-left: 0.5rem;
}

.arrow-down-button6 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(to bottom, #674fa3, #9f7dcc, #caa2f4);
  color: white;
  border: none;
  width: 12rem;
  height: 2rem;
  padding: 0 1rem;
  font-size: 0.8rem;
  margin-top: 0.5rem;
  border-radius: 5px;
  margin-left: 8px;
}

.arrow-down-button6 .button-text {
  flex: 1;
  text-align: center;
}

.arrow-down-button6 .arrow {
  margin-left: 0.5rem;
}

.dateGraf .rmdp-calendar {
  background: #6cb5dd;
}

.dateGraf .rmdp-week-day {
  color: white;
}

.dateGraf .rmdp-year-picker {
  background: #6cb5dd;
}

.dateGraf .rmdp-month-picker {
  background: #6cb5dd;
}

a[aria-label="Export"] {
  transform: scale(2);
  background-image: url("../img/lista_espera/descargar.svg");
  width: 1.5rem;
  height: 1.5rem;
  margin: 0;
  display: flex;
  background-repeat: no-repeat;
}

a[aria-label="Export"]:hover {
  background-image: url("../img/lista_espera/descargar.svg");
  width: 1.5rem;
  height: 1.5rem;
  margin: 0;
  display: flex;
  background-repeat: no-repeat;
  background-color: #00ffff00;
}

.am5exporting-menu-open .am5exporting-icon {
  background-image: url("../img/lista_espera/descargar.svg") !important;
  width: 1.5rem;
  height: 1.5rem;
  margin: 0;
  display: flex;
  background-repeat: no-repeat !important;
  background-color: transparent !important;
}

a[aria-label="Export"]:hover .am5exporting-menu-open {
  background-image: url("../img/lista_espera/descargar.svg");
  width: 1.5rem;
  height: 1.5rem;
  margin: 0;
  display: flex;
  background-repeat: no-repeat;
  background-color: #00ffff00;
}

.am5exporting path {
  display: none;
}

.am5exporting-icon {
  width: 30px;
  height: 26px;
  position: absolute;
  margin: 0;
  padding: 3px 4px;
  border-radius: 3px;
  background: rgba(255, 255, 255, 0);
}

.am5exporting-menu a {
  align-items: center;
}

.am5exporting-icon:focus {
  background-image: url("../img/lista_espera/descargar.svg") !important;
  width: 1.5rem;
  height: 1.5rem;
  margin: 0;
  display: flex;
  background-repeat: no-repeat !important;
  background-color: transparent !important;
}

.am5exporting-icon {
  background-image: url("../img/lista_espera/descargar.svg") !important;
  width: 1.5rem;
  height: 1.5rem;
  margin: 0;
  display: flex;
  background-repeat: no-repeat !important;
  background-color: transparent !important;
}
