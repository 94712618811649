/*
.MuiInput-underline:after {
  border-bottom: 2px solid #69b3da !important;
}

label {
  color: black !important;
}
*/

.MuiButton-root {
  text-transform: none !important;
}

.botonGeneral {
  background-color: #eaa028 !important;
  color: #fff !important;
  padding: 6px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
}
.botonGeneral:hover {
  background-color: #ca8619 !important;
}

.botonAcciones {
  background-color: #eaa028;
  color: #fff !important;
  padding: 6px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
}
