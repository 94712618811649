.chartContainer {
  padding-top: 4vh;
  width: 100%;
  height: 100%;
}

.chartGrid {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.chart {
  grid-area: 1 / 1 / 4 / 4;
}
.info {
  grid-area: 1 / 2 / 2 / 3;
}

.btnEnabled {
  background-color: #eaa028 !important;
  cursor: pointer;
}

.salusinGrafico {
  position: absolute;
  top: -99%;
  left: -4%;
  width: 143%;
  height: 237%;
}

.salusinCont {
  position: absolute;
  left: 66%;
  top: 8%;
}

.custom-tooltip {
  border-collapse: separate !important;
  border: 3px solid #e49f31;
  border-radius: 15px;
  padding-top: 25px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 25px;
  background-color: #f5c06b;
}

.totalArea {
  border-collapse: separate !important;
  border: 3px solid #eaa028;
  border-radius: 15px;
  background-color: #eaa028;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.totalText {
  grid-area: 1 / 1 / 2 / 4;
  color: white;
  font-size: 1.5vw;
  padding-left: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 15px;
}
.totalSalusin {
  grid-area: 1 / 4 / 2 / 5;
}

/* Graficos Covid */

.textColor {
  color: rgb(95, 95, 95);
}

.barGradient {
  background: linear-gradient(90deg, #db1849 0%, #bb0392 50%, #5b06a6 100%);
  background-size: 42vw 100%;
}

.barGradientStack {
  background: linear-gradient(90deg, #9248c4 0%, #363883 50%, #2926b9 100%);
  background-size: 42vw 100%;
}

.barGradientSmall {
  background: linear-gradient(90deg, #db1849 0%, #bb0392 50%, #5b06a6 100%);
  background-size: 35vw 100%;
}

.barGradientSmallStack {
  background: linear-gradient(90deg, #9248c4 0%, #363883 50%, #2926b9 100%);
  background-size: 35vw 100%;
}

.bubble {
  position: relative;
  top: 1vh;
  left: 1.5vw;
  background: #2b6ac9;
  color: #ffffff;
  font-family: Arial;
  font-size: 20px;
  line-height: 105px;
  text-align: center;
  width: 250px;
  height: 120px;
  border-radius: 5px;
  padding: 12px;
  white-space: nowrap;
}
.bubble:after {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  z-index: 0;
  border-style: solid;
  border-color: inherit;
  border-width: 0 7px 13px;
  top: -23%;
  left: 77%;
  margin-left: -20px;
}

.setcolorSev {
  border-color: #38a18f transparent !important;
}

.setcolorExt {
  border-color: #65329e transparent !important;
}

.vacunasArea {
  border-collapse: separate !important;
  border: 3px solid #2b6ac9;
  border-radius: 15px;
  background-color: #2b6ac9;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.vacunasText {
  grid-area: 1 / 1 / 2 / 4;
  color: white;
  text-align: center;
  font-size: 1.5vw;
  padding-left: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 15px;
}
.vacunasSalusin {
  grid-area: 1 / 4 / 2 / 5;
}

.salusinVac {
  position: relative;
  left: 39%;
}

.vacNumber {
  font-size: 150%;
}

.salusinCovid {
  position: absolute;
  top: -75%;
  right: -62%;
  width: 227%;
  height: 228%;
}

.depVac {
  border: 3px solid #38a18f !important;
  background-color: #38a18f !important;
  position: relative;
  left: 59%;
  padding-top: 0px !important;
  width: 16vw;
}
.vacunasTextSmall {
  grid-area: 1 / 1 / 1 / 5;
  color: white;
  text-align: center;
  font-size: 1.25vw;
  padding-left: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 15px;
}

.extVac {
  border: 3px solid #65329e !important;
  background-color: #65329e !important;
  position: relative;
  left: 59%;
  padding-top: 0px !important;
  width: 16vw;
}

.scrollingFix {
  margin-top: 2vh;
}

.downloadButton {
  margin: 0.5vh !important;
  align-items: center !important;
}

.buttonSelected {
  background-color: #dd8114 !important;
}

.buttonDetalle {
  margin-right: 4vw !important;
  margin-top: 19vh !important;
}

.armchResponsiveLine {
  width: 132%;
  height: 35vh;
}

.armchResponsiveBar {
  width: 132%;
  height: 40vh;
}

.armchResponsivePie {
  width: 132%;
  height: 40vh;
}

.metaBox {
  border-collapse: separate !important;
  border: 3px solid #2b6ac9;
  border-radius: 10px;
  background-color: #2b6ac9;
  width: 100%;
  position: relative;
  top: 25%;
}

.metaNum {
  color: white;
  text-align: center;
  font-size: 1.5vw;
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 5px;
}

.vacNumberSm {
  font-size: 125%;
}

.redtext {
  color: red;
}

.greentext {
  color: green;
}

.normaltext {
  color: #252525;
}

.notmain {
  font-weight: normal;
}

.smallTooltip {
  font-size: 15px !important;
}

.main {
  font-weight: bold;
}

.metaBarras {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.barras {
  grid-area: 1 / 1 / 2 / 2;
}
.comparativa {
  grid-area: 1 / 2 / 2 / 3;
  margin-left: 30px;
}

.ocultarScroll {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}
.ocultarScroll::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}
