/* width */
::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff0;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb:vertical {
  background: #bfced9;
  border-radius: 5px;
}

.react-confirm-alert-body {
  width: 540px;
}
