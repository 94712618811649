.bottom-footer {
  grid-area: bottom_area;
  background-repeat: no-repeat;
  background-size: cover;
}

.bottom-img {
  content: url("./../img/logo-fondo.svg");
  position: fixed;
  bottom: 0px;
  width: 40%;
  z-index: 0;
  left: 0px;
}

.chat-img {
  content: url("./../img/animaciones/parpadeo.gif");
  width: 150px;
  z-index: 21;
  margin-top: -19px;
  margin-right: -25px;
}

.divSalusinSinChat {
  float: right;
  height: 120px;
  margin-top: -82px;
}

.divSalusin {
  height: 120px;
  margin-top: -120px;
  z-index: -99;
  position: absolute;
}

.transicion-corriendo {
  width: 150px;
  right: 300px;
  transition-duration: 5s;
  transition-property: right;
}
.transicion-corriendo-vuelta {
  right: 50px;
  transition-duration: 5s;
  transition-property: right;
}

.salusin-corriendo {
  content: url("./../img/animaciones/corriendo.gif") !important;
  transform: scaleX(-1);
}
.salusin-corriendo-vuelta {
  content: url("./../img/animaciones/corriendo.gif") !important;
  transform: scaleX(1);
}
.salusin-hablando {
  content: url("./../img/animaciones/hablando.gif") !important;
}
.salusin-enojado {
  content: url("./../img/animaciones/enojado.gif") !important;
}
.salusin-mira-hacia-abajo {
  content: url("./../img/animaciones/mira-hacia-abajo.gif") !important;
}
.salusin-idle {
  content: url("./../img/animaciones/parpadeo.gif") !important;
}

.menu-chat.revisar {
  bottom: -318px !important;
}

.menu-chat.active {
  bottom: 5px !important;
  transition: 350ms;
  z-index: 42;
}

.menu-chat {
  width: 616px;
  height: 360px;
  justify-content: center;
  position: fixed;
  bottom: -359px;
  transition: 850ms;
  z-index: 40;
}

.chat-content {
  background-color: #73c3e2;
  height: 100%;
  width: 100%;
  float: left;
  border-radius: 8px;
  background-image: url("./../img/fondo-chat.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

.chat-top {
  position: relative;
  background-color: #1691c1;
  height: 42px;
  border-radius: 8px 8px 0px 0px;
}

.chat-menu {
  background-color: #eaa028;
  height: 50px;
  margin: 9px 18px 9px 18px;
  border-radius: 8px;
  text-align: center;
  color: white;
  font-weight: 500;
  padding: 5px;
  cursor: pointer;
}

.chat-menus {
  overflow-y: overlay;
  height: 318px;
  text-align: center;
  padding: 5px;
}

.chat-titulo {
  color: white;
  font-weight: bold;
  margin-top: 10px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}
.chat-cerrar {
  color: white;
  font-weight: bold;
  margin-top: 10px;
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.chat-pregunta {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.chat-icono {
  cursor: pointer;
}

.chat-icono .img-chat {
  width: 25px;
  margin-left: 10px;
  margin-top: 5px;
}

.contenedor-respuesta-chat {
  padding: 5px;
  text-align: justify;
  font-size: 1.3vw;
  background: white;
  border: 3px #eaa028 solid;
  width: 100%;
  border-radius: 11px;
  height: 100%;
}

.input-ingreso-rut {
  border-radius: 8px;
  color: black;
  padding: 5px;
  width: 85%;
  border: 3px #eaa028 solid;
  font-size: 1.2vw;
  margin-top: 5px;
}

.select-cesfams-chat {
  background-color: #ffffff;
  border-radius: 8px;
  text-align: center;
  color: black;
  font-size: 1.5vw;
  width: 70%;
  border: 3px #eaa028 solid;
}

.botones-grabaciones-chat {
  width: 51px;
  cursor: pointer;
  background: white;
  border-radius: 3vw;
  margin: 0px 1vw;
}

.textarea-ingreso-rut {
  border-radius: 8px;
  color: black;
  padding: 5px;
  width: 85%;
  border: 3px #000 solid;
  font-size: 1.2vw;
  margin-top: 8px;
  height: 8vw;
}

.contenedor-ingreso-chat {
  padding: 5px;
  text-align: center;
  font-size: 1.3vw;
}

.boton_accion_chat {
  background-color: #eaa028;
  border-radius: 8px;
  color: white;
  font-weight: 500;
  padding: 5px;
  cursor: pointer;
  width: 60%;
  font-size: 1.5vw;
  border: 3px #fff0 solid;
}

.boton_accion_menu_chat {
  background-color: #eaa028;
  border-radius: 8px;
  color: white;
  font-weight: 500;
  padding: 5px;
  cursor: pointer;
  width: 72%;
  font-size: 1.5vw;
  border: 3px #fff0 solid;
  margin-top: 1vw;
}

.boton_salir_chat {
  position: absolute;
  bottom: 10px;
  width: 2.5vw;
  right: 10px;
  cursor: pointer;
}

.boton_volver_chat {
  position: absolute;
  bottom: 10px;
  width: 2.5vw;
  left: 10px;
  cursor: pointer;
}

.contenedor-tabla-chat {
  display: flex;
  justify-content: center;
}

.table-chat {
  border: 3px #eaa028 solid;
  background: #fff;
  font-size: 18px;
  border-radius: 7px;
  width: 92%;
  text-align: center;
}

.table-chat thead tr th {
  border-bottom: 3px solid #eaa028;
}

.select-tabla-chat {
  background-color: #ffffff;
  border-radius: 8px;
  text-align: center;
  color: black;
  font-size: 1.3vw;
  width: 38%;
  border: 3px #000 solid;
  margin: 10px 0px;
}

.select-prioridad-tabla-chat {
  background-color: #ffffff;
  border-radius: 8px;
  text-align: center;
  color: black;
  font-size: 1.3vw;
  border: 3px #000 solid;
  margin: 10px 0px;
  margin-right: 11px;
}

.boton_tabla_chat {
  background-color: #eaa028;
  border-radius: 8px;
  color: white;
  font-weight: 500;
  padding: 5px;
  cursor: pointer;
  width: 20%;
  margin: 0 10px;
  font-size: 1vw;
  border: 3px #fff0 solid;
}

.botones_acciones_tabla_chat {
  width: 2vw;
  margin: 0px 5px;
  cursor: pointer;
}

.titulo_chat {
  font-size: 22px;
  font-weight: bold;
  color: #666666;
}

.contendor_chat_datos {
  padding: 5px;
  font-size: 1.3vw;
  background: white;
  border: 3px #eaa028 solid;
  width: 100%;
  border-radius: 11px;
  height: 100%;
  text-align: left;
}

.cargaHorarioOpciones {
  margin-top: -4vw !important;
  padding-top: 0px !important;
}

@media (min-width: 1700px) {
  .cargaHorarioOpciones {
    margin-top: 0vw !important;
  }
}
