.numeroGrid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 5px;
}

.infoPasa {
  grid-area: 1 / 1 / 2 / 2;
}
.nums {
  grid-area: 1 / 2 / 2 / 3;
}

.btnOpcion {
  float: left;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  width: fit-content;
  margin: 10px;
}

.elmNumeros {
  border-collapse: separate !important;
  border: 3px solid #eaa028;
  border-radius: 15px;
  padding-top: 25px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 25px;
}

.tiempoEspera {
  border-collapse: separate !important;
  border: 3px solid #eaa028;
  border-radius: 15px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}

.elmNumerosGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 0px;
}

.elmData1 {
  grid-area: 1 / 1 / 2 / 2;
}
.elmData2 {
  grid-area: 1 / 2 / 2 / 3;
}
.elmData3 {
  grid-area: 1 / 3 / 2 / 4;
}

.inline {
  display: inline;
  float: left;
  font-size: 18px;
  width: 100%;
}

.botonVm {
  align-self: top;
  height: 22px;
}

.tiempoEspera h3 {
  text-align: center;
  font-size: 200%;
  color: rgb(95, 95, 95);
}

.tiempoEspera p {
  text-align: center;
}

.numeroAnterior {
  border-collapse: separate !important;
  border: 3px solid #eaa028;
  border-radius: 15px;
  padding: 10px;
  width: 100%;
  height: 100%;
}

.numeroAnterior p {
  text-align: center;
  align-self: center;
}

.numeroAnterior .numAntNum {
  text-align: start;
  align-self: center;
  font-size: 3vw;
  color: rgb(95, 95, 95);
}

.numAntText {
  grid-area: 2 / 1 / 3 / 2;
}
.numAntNum {
  grid-area: 2 / 2 / 3 / 3;
}

.numsGrid {
  display: grid;
  grid-template-columns: 2fr;
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 13px;
}

.numInfo {
  grid-area: 1 / 1 / 2 / 2;
  align-items: center;
}
.attInfo {
  grid-area: 2 / 1 / 3 / 2;
  align-items: center;
}

.numActual {
  text-align: center !important;
  align-self: center;
  color: #eaa028;
  font-size: 4vw;
}

.numSig {
  text-align: center !important;
  align-self: center;
  color: #ebb764;
  font-size: 2vw;
}

.borde {
  border-collapse: separate !important;
  border: 3px solid #eaa028;
  border-radius: 8px;
  max-width: 60%;
  margin: 0 auto;
}

.bordeSmall {
  border-collapse: separate !important;
  border: 3px solid #ebb764;
  border-radius: 5px;
  max-width: 45%;
  margin: 0 auto;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnCentrado {
  align-self: center;
  text-align: center;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  width: 70%;
}

.btnCentradoPresenta {
  align-self: center;
  text-align: center;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  margin-right: 10px;
  width: 40%;
}

.pendientes {
  padding-top: 10px;
  padding-bottom: 10px;
}

.pendientes hr {
  margin-top: 10px;
  margin-bottom: 10px;
  border-top: 3px solid #eaa028;
}

.pendientes p {
  text-align: center;
}

.numeroGrid p {
  color: rgb(95, 95, 95);
}

/* Especificos del Pasanumeros 2 */

.pnum2Grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 0px;
}

.p2numInfoPos {
  grid-area: 1 / 1 / 2 / 2;
}
.p2detInfoPos {
  grid-area: 1 / 2 / 2 / 6;
  padding-right: 75px;
}
.pnum2Grid p {
  color: rgb(95, 95, 95);
}

.p2activeNum {
  text-align: center !important;
  align-self: center;
  color: #eaa028 !important;
  font-size: 300%;
}

.p2left {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.p2prevNum {
  text-align: center !important;
  align-self: center;
  font-size: 350%;
  color: rgb(95, 95, 95);
}

.p2elmNumerosGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 1px;
  grid-row-gap: 0px;
}

.p2elmData1 {
  grid-area: 1 / 1 / 2 / 2;
  padding-top: 25px;
  padding-bottom: 25px;
}
.p2elmData2 {
  grid-area: 1 / 2 / 2 / 3;
  padding-left: 20px;
  padding-top: 25px;
  padding-bottom: 25px;
}
.p2elmData3 {
  grid-area: 1 / 3 / 2 / 4;
  padding-top: 25px;
  padding-bottom: 25px;
}

.roundBorder {
  border-collapse: separate !important;
  border: 5px solid #eaa028;
  border-radius: 500%;
  padding-top: 53px;
  padding-bottom: 25px;
  height: 18vh;
  max-width: 70%;
  margin: 0 auto;
}

.p2pendientes {
  align-items: center;
  max-width: none !important;
}

.waitRed {
  border: 3px solid #bd142a !important;
}

.waitRed p {
  color: #bd142a !important;
}

.waitYel {
  border: 3px solid #a8a622 !important;
}

.waitYel p {
  color: #a8a622 !important;
}

.waitGr {
  border: 3px solid #2ba152 !important;
}

.waitGr p {
  color: #2ba152 !important;
}

.p2tiempoEspera p {
  text-align: center;
  font-size: 180%;
  color: rgb(95, 95, 95);
}

.centerText {
  text-align: center;
}

.p2tiempoEspera {
  border-collapse: separate !important;
  border: 3px solid #eaa028;
  border-radius: 15px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}

.p2numsGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 0.45fr);
  grid-column-gap: 15px;
  grid-row-gap: 0px;
}

.currInfo {
  grid-area: 1 / 1 / 2 / 5;
}
.prevNum {
  grid-area: 2 / 1 / 3 / 2;
}
.prevInfo {
  grid-area: 2 / 2 / 3 / 5;
}

.lowerPrevPad {
  padding-top: 56px;
  padding-bottom: 56px;
}

.p2subPrevInfo {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  align-items: center;
}

.sub1 {
  grid-area: 1 / 1 / 2 / 2;
  padding-left: 30px;
}
.sub2 {
  grid-area: 1 / 2 / 2 / 3;
}

.heightFix {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

/* Especificos del pasanumeros 3 */

.textColor {
  color: rgb(95, 95, 95) !important;
}

.smallBox {
  border-collapse: separate !important;
  border: 3px solid #eaa028;
  border-radius: 8px;
  max-width: 80%;
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
}

.statusGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 5px;
  grid-row-gap: 0px;
}

.pending {
  grid-area: 1 / 1 / 2 / 2;
}
.remtime {
  grid-area: 1 / 2 / 2 / 3;
}

.p3grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 10px;
}

.p3nums {
  grid-area: 1 / 1 / 2 / 2;
}
.p3currInfo {
  grid-area: 2 / 1 / 3 / 2;
}
.p3prevInfo {
  grid-area: 3 / 1 / 4 / 2;
}

.p3numsGrid {
  display: grid;
  grid-template-columns: 1fr 1.5fr repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  align-items: center;
}

.p3Prevnum {
  grid-area: 1 / 1 / 2 / 2;
}
.p3currNum {
  grid-area: 1 / 2 / 2 / 3;
}
.p3nextNum {
  grid-area: 1 / 3 / 2 / 4;
}
.p3button {
  grid-area: 1 / 4 / 2 / 5;
}

.grbox {
  border: 3px solid rgb(138, 138, 138) !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  max-width: 80%;
}

.vrdbox {
  border: 3px solid #2ba152 !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  max-width: 60%;
}

.currNumVrd {
  color: #2ba152 !important;
  font-size: 450%;
}

.orangebox {
  border: 3px solid #eaa028 !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  max-width: 80%;
}

.nxtNumOrg {
  color: #eaa028 !important;
}

.p3InfoGrid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.box {
  grid-area: 1 / 1 / 2 / 5;
}

.p3InfoGrid p {
  color: rgb(95, 95, 95);
}

.boxGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  align-items: center;
}

.p3numBox {
  grid-area: 1 / 1 / 2 / 2;
}
.p3waitTime {
  grid-area: 1 / 2 / 2 / 3;
}
.p3data1 {
  grid-area: 1 / 3 / 2 / 4;
}
.p3data2 {
  grid-area: 1 / 4 / 2 / 5;
}

.p3prevNum {
  text-align: center !important;
  align-self: center;
  font-size: 250% !important;
  color: rgb(95, 95, 95);
}

.p3subBoxBig {
  border-collapse: separate !important;
  border: 3px solid #eaa028;
  border-radius: 8px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  max-width: 70%;
}

.p3prevInfoGrid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.prevBox {
  grid-area: 1 / 1 / 2 / 4;
}

.p3prevInfoGrid p {
  font-size: 90%;
  color: rgb(95, 95, 95);
}

.makeBorderGray {
  border: 3px solid rgb(138, 138, 138) !important;
}

.salusin {
  float: right;
  position: absolute;
  display: inline;
  top: 3.7vh;
  right: 0.15vw;
  bottom: 57vh;
  left: 80vw;
}

.middleOverride {
  height: 100% !important;
}

.btnDisabled {
  background-color: #9b9b9b !important;
}

.btnEnabled {
  background-color: #eaa028 !important;
  cursor: pointer;
}

.btnEnabledOpc {
  background-color: #eaa028 !important;
  cursor: pointer;
}

.btnAccionA {
  background-color: #227648 !important;
  cursor: pointer;
}

.btnAccionR {
  background-color: red !important;
  cursor: pointer;
}

.divBordeTablaHistorialAtenciones {
  width: 100%;
  position: relative;
}

.fechaFiltro {
  position: absolute;
  left: 0vw;
  top: 1vw;
  z-index: 99999;
  font-size: 1vw;
}

.divBordeTablaHistorialAtenciones div[class*="Component-horizontalScrollContainer"] table,
.divBordeTablaHistorialAtenciones div[class*="jss"] table {
  border-collapse: separate !important;
  border: 3px solid #eaa028;
  border-radius: 15px;
}

.divBordeTablaHistorialAtenciones div[class*="Component-horizontalScrollContainer"] table .MuiTableCell-head,
.divBordeTablaHistorialAtenciones div[class*="jss"] table .MuiTableCell-head {
  border-bottom: 3px solid #eaa028 !important;
  background-color: #e8ecef !important;
  border-radius: 20px 20px 0 0 !important;
}

.pacientesAtendidos .MuiDialog-paperWidthSm {
  background-image: url("./../img/marca_de_agua20.svg");
  background-repeat: no-repeat;
  background-position: right bottom;
}

.contenedorCantPac {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cantidadPacientes {
  border-collapse: separate !important;
  border: 6px solid #eaa028;
  border-radius: 10px;
  margin: 0 auto;
  font-size: 3vw;
  padding: 0 1vw;
  color: #eaa028;
}

.textCantPac {
  padding: 1vw;
  color: #000;
  font-weight: 600;
  font-size: 1vw;
}

.MuiDialog-paperWidthSm {
  max-width: none !important;
}

.btnHistorial {
  background-color: #eaa028 !important;
  cursor: pointer;
  float: right;
}

.modalSolicitudTrabajo .MuiDialog-paperWidthSm {
  width: auto;
}
