.media-bridge {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 90;
  top: 0px;
  background: black;
}

.accionesFooter {
  width: 5vw;
  margin: 0.5vw;
  cursor: pointer;
}

.mute-video {
  transition: 120ms;
  border-radius: 48px;
  display: block;
  margin: 1vh;
}

.mute-video:hover,
.mute-video.on {
  background: #407cf7;
}

.mute-audio {
  transition: 40ms;
  border-radius: 48px;
  display: block;
  margin: 1vh;
}

.mute-audio:hover,
.mute-audio.on {
  background: #407cf7;
}

.circleAcciones {
  fill: #666;
  fill-opacity: 0.6;
}

video {
  width: 100%;
  height: 100%;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.remote-stream {
  position: absolute;
  width: 100%;
  height: 100%;
}

.remote-stream video {
  object-fit: contain;
  object-position: center;
}

.local-stream {
  position: absolute;
  top: 1vw;
  right: 1vw;
  width: 18%;
}

.local-stream video {
  border-radius: 5px;
  z-index: 10;
}

.local-stream video {
  -webkit-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
}

.info1 {
  position: absolute;
  z-index: 1000;
  top: 7vw;
  left: -1vw;
  width: 23vw;
  border: 4px #fff solid;
  text-align: center;
  padding: 10px;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border: 6px #fff solid;
  border-right: 6px #fff solid;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.infoacom {
  position: absolute;
  z-index: 1000;
  top: 17vw;
  left: -1vw;
  width: 23vw;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  display: none;
  background-repeat: no-repeat;
  background-position: center;
  border: 6px #fff solid;
  background-size: cover;
  border-right: 6px #fff solid;
}

.DataAlerta {
  color: #fff;
  font-size: 1.3vw;
  width: 15vw;
}

.info2 {
  position: absolute;
  z-index: 1000;
  bottom: 7vw;
  right: 1vw;
  width: 32vw;
  height: 16vw;
  cursor: pointer;
  display: none;
  padding: 1vw;
  border-radius: 10px;

  background-size: cover;
  background-repeat: no-repeat;
}

.info4 {
  position: absolute;
  z-index: 1000;
  bottom: 7vw;
  right: 1vw;
  width: 32vw;
  height: 16vw;
  cursor: pointer;
  display: none;
  padding: 1vw;
  border-radius: 10px;

  background-size: cover;
  background-repeat: no-repeat;
}

.info5 {
  position: absolute;
  z-index: 1000;
  bottom: 7vw;
  right: 1vw;
  width: 32vw;
  height: 9vw;
  cursor: pointer;
  display: none;
  padding: 1vw;
  border-radius: 10px;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
}

.info6 {
  position: absolute;
  z-index: 1000;
  bottom: 7vw;
  right: 1vw;
  width: 32vw;
  height: 16vw;
  cursor: pointer;
  display: none;
  padding: 1vw;
  border-radius: 10px;

  background-size: cover;
  background-repeat: no-repeat;
}

.info7 {
  position: absolute;
  z-index: 1000;
  bottom: 7vw;
  right: 1vw;
  width: 32vw;
  height: auto;
  cursor: pointer;
  display: none;
  padding: 1vw;
  border-radius: 10px;

  background-size: cover;
  background-repeat: no-repeat;
}

.info8 {
  position: absolute;
  z-index: 1000;
  top: 14vw;
  left: 0vw;
  width: 23vw;
  padding: 10px;
  border-radius: 10px;

  background-repeat: no-repeat;
  background-position: right;
  color: #fff;
  display: none;
  font-size: 1.3vw;
}

.info9 {
  position: absolute;
  z-index: 1000;
  top: 14vw;
  left: 10px;
  width: 23vw;
  padding: 10px;
  border-radius: 10px;

  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
  color: #fff;
  font-size: 1.5vw;
  display: none;
}

.info10 {
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0vw;
  width: 20vw;
  height: 80%;
  padding: 10px;
  border-radius: 10px;
  display: none;
}

.info11 {
  position: absolute;
  z-index: 1000;
  bottom: 7vw;
  right: 1vw;
  width: 32vw;
  height: 16vw;
  cursor: pointer;
  display: none;
  padding: 1vw;
  border-radius: 10px;

  background-size: cover;
  background-repeat: no-repeat;
}

.info12 {
  position: absolute;
  z-index: 1000;
  bottom: 7vw;
  right: 10vw;
  width: 32vw;
  height: 13vw;
  cursor: pointer;
  display: none;
  padding: 1vw;
  border-radius: 10px;
  background-size: cover;
  background-repeat: no-repeat;
}

.info12_contendor {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.info12_btnsV {
  width: 87%;
  border: 0px #fff solid;
  border-radius: 10px;
  background: #3cbf71;
  font-size: 1.5vw;
  color: #fff;
  cursor: pointer;
  padding: 0.5vw;
}

.info12_btnsN {
  width: 11vw;
  border: 0px #fff solid;
  border-radius: 10px;
  background: #ff9912;
  font-size: 1.5vw;
  color: #fff;
  cursor: pointer;
  padding: 0.5vw;
}

.datosPaciente {
  position: relative;
  color: #fff;
  font-size: 1vw;
  text-align: start;
  margin-left: 0.5vw;
  overflow-wrap: break-word;
  width: 100%;
}

.labelMensaje {
  color: #fff;
  font-size: 1.5vw;
}

.textArea {
  width: 100%;
  height: 8vw;
  background-color: #ffffff00;
  font-size: 1.5vw;
  border: 4px #fff solid;
  border-radius: 10px;
  color: #fff;
  margin-bottom: 1vw;
  padding: 5px;
}

.btnGuardar {
  width: 10vw;
  border: 0px #fff solid;
  border-radius: 10px;
  background: #3cbf71;
  font-size: 1.5vw;
  color: #fff;
  cursor: pointer;
  padding: 0.5vw;
  margin-right: 1vw;
}

.btnCerrar {
  width: 10vw;
  border: 0px #fff solid;
  border-radius: 10px;
  background: #ff9912;
  font-size: 1.5vw;
  color: #fff;
  cursor: pointer;
  padding: 0.5vw;
}

.botonesTabla {
  border: 3px #fff solid;
  border-radius: 25px;
  background: #ffffff00;
  color: #fff;
  font-size: 1vw;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
}

.observacionDetalle {
  height: 15vh;
  overflow-y: scroll;
}

/* width */
.observacionDetalle::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.observacionDetalle::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
  border-radius: 5px;
}

/* Handle */
.observacionDetalle::-webkit-scrollbar-thumb {
  background: #6cb5dd;
  border-radius: 5px;
}

/* Handle on hover */
.observacionDetalle::-webkit-scrollbar-thumb:hover {
  background: #6cb5dd;
}

.MensajeEnviadoM {
  height: 10vh;
  overflow-y: scroll;
  width: 100%;
  font-size: 1.2vw;
}

.tituloAcciones {
  font-size: 1.5vw;
  color: #fff;
}

.contenedorAtencion {
  margin-bottom: 1vw;
}

.textAreaPopup {
  width: 100%;
  height: 8vw;
  background-color: #ffffff00;
  font-size: 1.5vw;
  border: 4px #fff solid;
  border-radius: 10px;
  color: #fff;
}

.inputArchivo {
  border: 3px #fff solid;
  border-radius: 5px;
  margin-top: 5px;
  padding: 5px;
  background: #ffffff00;
  color: #fff;
  font-size: 1.5vw;
  width: 100%;
  margin-bottom: 1vw;
}

.videoOculto {
  display: none;
}

.contenedorPdfImg {
  margin-top: 2vw;
  height: 80%;
  width: 100%;
  position: relative;
  display: flex;
  align-content: center;
  flex-direction: column;
  align-items: center;
}

.preview {
  width: inherit;
  height: inherit;
}

.pdfViewerGuardado {
  margin-top: 1vw;
  width: inherit;
  height: 100% !important;
}

.imagenGuardada {
  margin-top: 1vw;
  height: 100%;
  text-align: center;
  width: 100%;
}

.btnCerrarPopup {
  position: absolute;
  width: 2vw;
  right: 1vw;
  top: 1vw;
  cursor: pointer;
  z-index: 999;
}

.btnDescargarArchivo {
  width: 15%;
  cursor: pointer;
  float: right;
}

.nombreArchivoDescargar {
  color: #fff;
  width: 50%;
  word-break: break-all;
  letter-spacing: 0.021em;
  float: left;
}

.accionesArchivos {
  margin-top: 1vw;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

* {
  outline: none !important;
}

.boton {
  padding: 6px 20px;
  color: white !important;
  border-color: transparent;
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
  font-size: 1.3vw;
}

.botonRojo {
  background-color: #bf4b3c !important;
}

.botonVerde {
  background-color: #3cbf71 !important;
}

.botonNaranjo {
  background-color: #eaa028 !important;
}

.acompTextArea {
  background-color: #ffffff00;
  border: 2px #fff solid;
  border-radius: 8px;
  color: #fff;
  margin-top: 3px;
  margin-bottom: 3px;
  font-size: 1.2vw;
  width: 75%;
  padding: 5px;
}

.acompSelect {
  border: 2px #fff solid;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 0px;
  background: #ffffff00;
  color: #fff;
  font-size: 1.2vw;
  width: 75%;
}

.contendorFooterAcciones {
  float: left;
  margin-left: 1vw;
  margin-left: 1vw;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.timerGrupal {
  float: right;
  font-size: 1.3vw;
  width: 8.5vw;
  border: 0.3vw #fff solid;
  padding: 0.5vw;
  border-radius: 1vw;
  margin-left: 1vw;
  display: none;
}

.timer {
  font-size: 1.3vw;
  position: absolute;
  z-index: 1000;
  top: 14vw;
  left: 0vw;
  width: 8.5vw;
  height: 11vh;
  border: 4px #fff solid;
  padding: 5px;
  border-radius: 10px;
  background-repeat: no-repeat;
  display: none;
  text-align: center;
}

.countdown {
  font-size: 1.3vw;
  position: absolute;
  z-index: 1000;
  top: 14vw;
  left: 9.5vw;
  width: 8.5vw;
  height: 11vh;
  border: 4px #fff solid;
  padding: 5px;
  border-radius: 10px;
  background-repeat: no-repeat;
  display: none;
  text-align: center;
}

.contenedorImg {
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: row;
}

.cardImg {
  position: relative;
}

.card-inner::after {
  position: absolute;
  right: -1vw;
  top: 50%;
  transform: translateY(-50%);
  background-color: #eaa028;
  content: "";
  width: 2.2vw;
  height: 0.2vw;
  z-index: 1;
}

.cardDatos {
  position: relative;
  height: 100%;
}

.card-innerDatos {
  position: absolute;
  right: -2.2vw;
  top: 50%;
  transform: translateY(-50%);
  background-color: #eaa028;
  content: "";
  width: 1vw;
  height: 0.2vw;
}

.contenedorDatosMedico {
  border: 0.3vw #eaa028 solid;
  border-radius: 20px;
  padding: 1vw;
  font-size: 1.2vw;
  width: 50%;
  float: left;
}

.contenedorImgMedico {
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: row;
  width: 33%;
  float: left;
}

.contenedorImgMedico img {
  width: 6vw;
  margin: 1vw;
  cursor: pointer;
  position: relative;
  z-index: 2;
}

.contenedorTdatos {
  display: flex;
  justify-content: center !important;
}

.titulosMedico {
  font-size: 2.5vw;
  color: #464646;
  font-weight: bold;
}

.mensajeTxt {
  color: #000;
  float: left;
  font-size: 1.2vw;
  margin-top: 1vw;
}

.table-obs {
  border: 3px #eaa028 solid;
  background: #fff;
  font-size: 18px;
  border-radius: 7px;
  width: 92%;
  text-align: center;
}

.footer-video {
  position: absolute;
  width: 100%;
  bottom: 0px;
}

.tablaObs {
  width: 98%;
  height: 100%;
  border: 4px #fff solid;
  border-radius: 10px;
  overflow-y: scroll;
}

.tablaObs thead tr td {
  font-size: 1vw;
  color: #fff;
}

.tablaObs tbody td,
.tablaObs thead td {
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: center;
  color: #fff;
}

.btnModalMedico {
  border: 0px #fff solid;
  border-radius: 50px;
  background: #f1ae36;
  -webkit-box-shadow: 0 2px 6px rgb(0 0 0 / 20%);
  box-shadow: 0 2px 6px rgb(0 0 0 / 20%);
  color: #fff;
  padding: 7px;
  width: 13vw;
  font-size: 2vw;
  margin: 1vw;
  position: relative;
  cursor: pointer;
}

.btnSeleccionadoMedico {
  background: #d87d07 !important;
}
.btnAccionesMedico {
  background: #eaa028;
  border-radius: 5px;
  border: 3px #fff solid;
  font-size: 1.5vw;
  color: #fff;
  width: 20%;
  margin: 1vw;
  cursor: pointer;
}

.videoApp {
  object-fit: contain !important;
}

.selectReservarMed {
  background-color: #ffffff;
  text-align: center;
  font-size: 1.3vw;
  width: 100%;
  height: 35px;
  color: #000;
  border-radius: 5px;
  margin: 10px 0px;
  flex: 1 1;
  border: gray solid 1px;
}

.btnDisabledMed {
  background: #787878;
  color: #8c8c8c;
  cursor: default;
}

.btnGuardarM {
  width: 10vw;
  border: 0px #fff solid;
  border-radius: 10px;
  background: #3cbf71;
  font-size: 1.5vw;
  color: #fff;
  margin: 0vw 5px;
  cursor: pointer;
}

.btnCerrarM {
  width: 10vw;
  border: 0px #fff solid;
  border-radius: 10px;
  background: #ff9912;
  font-size: 1.5vw;
  color: #fff;
  margin: 0vw 5px;
  cursor: pointer;
}

.contenedorPacientes {
  height: 25vw;
  overflow-y: scroll;
}

.fechaActiva {
  width: 80%;
  height: 80%;
  background: #00000073;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 30px;
  transform: translate(-50%, -50%);
}

.estadoSala {
  background: #2f9bdd;
  border-radius: 5px;
  padding: 1px;
}

.accionesVideo {
  position: absolute;
  left: 0px;
  top: 0px;
  display: flex;
}

.infoAtrasado {
  position: absolute;
  width: 24vw;
  padding: 1vw;
  padding-right: 1vw;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
  border: 6px #fff solid;
  border-left: 3px #fff solid;
  right: -1vw;
  font-size: 1.3vw;
  color: #fff;
  bottom: 8vw;
  text-align: center;
  display: none;
}

.infoPacientes {
  position: absolute;
  width: 24vw;
  height: 12vw;
  padding: 1vw;
  padding-right: 1vw;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
  border: 6px #fff solid;
  border-left: 3px #fff solid;
  right: -1vw;
  font-size: 1.3vw;
  color: #fff;
  bottom: 22vw;
}

.dialog-cargando {
  z-index: 1300 !important;
}
