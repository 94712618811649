.input {
  border: solid 3px #eaa028;
  border-radius: 10px;
  height: 2.2rem;
  padding: 10px;
  background: none;
  font-size: 1rem;
  width: 100%;
}

.input::-webkit-calendar-picker-indicator {
  filter: invert(50%) sepia(100%) saturate(300%);
}
.selectores {
  padding: 7px;
  border: 0;
  background: none;
  height: 2.2rem;
  font-size: 1rem;
  width: 100%;
  margin: 0 !important;
  border: solid 3px #eaa028;
  border-radius: 10px;
}
.selectores path {
  fill: #eaa028;
}

.contenedor_filaCrearTotem {
  display: flex;
  margin-bottom: 4.5% !important;
}

.MargenGrid {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 20px !important;
}
.MargenGridADM {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.accesoTotem {
  width: 33%;
  height: 4.5rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
  color: #fff;
  padding: 9px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 20px;
  background-color: #eaa028 !important;
}

.centrarAccesoTotem {
  display: flex;
  justify-content: flex-end;
}

div.MuiFormControl-marginNormal {
  margin: 0 !important;
}

.MuiFormControl-marginNormal {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.tituloSeccion {
  margin: 40px 0px 30px 0px;
  font-size: 1.9rem;
  text-align: center;
  color: black;
}

.audioMute {
  width: 50px;
  margin-left: 10px;
}

.audioMute:hover {
  cursor: pointer;
}
.audioMute path {
  fill: #eaa028;
}

.num_pantallas {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tam_icon_totem {
  width: 6%;
  margin-right: 2%;
  cursor: pointer;
}

.Contenedor_crearTotem {
  padding: 0px !important;
  width: "100%";
}

.btn_crearTotem {
  width: 100%;
  height: 7rem;
}

.inputTextArea {
  width: 100%;
  height: 100%;
  padding: 10px;
  border: 3px solid #eaa028;
  border-radius: 10px;
  background: none;
  font-size: 1rem;
}
.inputFile {
  border: solid 3px #eaa028;
  padding: 5px;
  border-radius: 10px;
  width: 100%;
}

.inputFile::file-selector-button {
  background-color: #eaa028;
  color: white;
  border: none;
  border-radius: 30px;
  padding: 2px;
  width: 50%;
}
.inputVolumen {
  margin-bottom: 10px;
  width: 100%;
  color: "#eaa028";
}

.helpIcon {
  margin-left: 10px;
  width: 20px;
}

.helpIcon path {
  fill: #eaa028;
}

.textoTitulos {
  font-size: 20px !important;
  margin-bottom: 10px !important;
  font-weight: bold !important;
  display: flex;
  align-items: center;
}
.btnAdministrar {
  width: 8.5vh;
  margin-left: 1%;
  cursor: pointer;
}

.btnAgregarAcceso {
  position: relative;
  float: right;
  z-index: 9;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  color: #fff;
  padding: 9px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  margin-right: 20px;
  background-color: #eaa028 !important;
}

.divBordeTablaAccesos {
  position: relative;
}

.divBordeTablaAccesos
  div[class*="Component-horizontalScrollContainer"]
  table
  .MuiTableCell-head {
  border-bottom: 3px solid #eaa028 !important;
  background-color: #e8ecef !important;
  border-radius: 20px 20px 0 0 !important;
}

.divBordeTablaAccesos div[class*="Component-horizontalScrollContainer"] table {
  border-collapse: separate !important;
  border: 3px solid #eaa028;
  border-radius: 5px;
}

.divBordeTablaAccesos .MuiTableCell-root {
  text-align: center !important;
  font-size: 1rem !important;
}

.checkbox path {
  fill: #eaa028;
}

.csvReader div {
  border: none !important;
  padding: 0px !important;
}
.csvReader div div {
  border: solid 3px #eaa028 !important;
  padding: 5px;
  border-radius: 10px;
  width: 100%;
  padding: 10px !important;
  font-size: 0.8rem;
}

.pacientesAtendidos .MuiDialog-paperWidthSm {
  background-image: url("./../img/marca_de_agua20.svg");
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 100%;
}

.visible path {
  fill: #eaa028;
}
