.chat .MuiDialog-paperWidthSm {
  max-width: inherit;
  width: 400px !important;
}

.contenedor-chat {
  margin-top: 20px;
  background-color: #e8ecef;
  height: 500px;
  border: 1px solid #a39d9d;
  border-radius: 10px;
  background-image: url("./../img/marca_de_agua20.svg");
  background-repeat: no-repeat;
  background-position: right bottom;
  overflow-y: scroll;
}

.contenedor-mensaje {
  width: 100%;
  min-height: 10px;
  padding: 5px 5px 5px 10px;
  overflow-y: scroll;
}

.interno {
  margin-top: 10px;
  width: 80%;
  font-size: 14px;
  padding: 8px 20px 8px 20px;
  float: right;
  border-radius: 8px 8px 0px 8px;
  background-color: #1691c1;
  color: white;
}

.externo {
  margin-top: 10px;
  width: 80%;
  padding: 8px 20px;
  font-size: 14px;
  float: left;
  border-radius: 0px 8px 8px 8px;
  background-color: #166ac1;
  color: white;
}

.externo .nombreUsuario {
  padding: 0px 10px 10px 5px;
}

.bottom-chat {
  height: 50px;
  /* border: 1px solid black; */
  margin-top: 20px;
}
.top-chat {
  /*background-color: yellow;
  border: 1px solid black;*/
}

.chat-nombre-usuario {
  padding: 9px 9px 0px 9px;
  font-weight: bold;
  font-size: 20px;
}

.chat-estado-usuario {
  padding: 0px 9px 9px 9px;
  font-size: 14px;
}

.chat-hora {
  float: right;
  /* width: 100%; */
  font-size: 9px;
  color: #fff;
  margin-top: 5px;
}

.chat-mark {
  font-size: 8px;
  color: white;
  margin-left: 3px;
}

.chat-mark .active {
  font-size: 8px;
  color: white;
}

.enviar-mensaje {
  float: left;
  width: 245px;

  border: 1px solid black;
  padding: 3px;
  border-radius: 20px;
  margin: 5px 2px;
}

.enviar-audio {
  float: right;
  width: 35px;
  margin: 6px;
}

.chat-input {
  float: left;
  border: none;
  height: 24px;
  min-height: 24px;
  padding-left: 10px;
  border-radius: 10px;
  min-width: 200px;
}

.chat-camera {
  float: right;
  margin-right: 5px;
  cursor: pointer;
}

.chat-file {
  float: right;
  margin-right: 5px;
  cursor: pointer;
}

.chat-mic {
  width: 30px !important;
  height: inherit !important;
  cursor: pointer;
}

.chat-badge-fecha {
  font-weight: 900;
  font-size: 15px;
  /* background: #dbd8d5; */
  padding: 5px 15px 5px 15px;
  border-radius: 15px;
  color: #686868;
  border: 1px solid #a39d9d;
}

.chat-div-badge-fecha {
  text-align: center;
  margin: 10px 10px 0px 10px;
}

.chat-img-mensaje {
  width: 100%;
  border-radius: 8px;
  cursor: pointer;
}

.chat-pdf-mensaje {
  width: 100%;
  border-radius: 8px;
  cursor: pointer;
}

.imagenFullScreen .MuiDialog-paperWidthSm {
  max-width: inherit;
}

.imagenFullScreen .MuiDialog-paperWidthSm .MuiDialogContent-root {
  padding: 0px !important;
}
