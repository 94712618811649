.modalModificarUsuarioDatos,
.modalAgregarUsuarioDatos {
  width: 40%;
  margin-top: 25px;
}

#simple-popover .MuiPaper-root {
  padding: 10px;
  border: 1px solid black;
}

.botonCiclos {
  float: left !important;
  color: white !important;
}

.fechaBeneficio {
  border: none;
  border-bottom: 1px solid #a5a5a5;
  background-color: #e8ecef;
}

.selectTerminoBeneficio {
  padding: 5px;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid black;
}

.fondoRojoBeneficio {
  background-color: #bf4b3c !important;
  padding: 6px 8px;
}
.fondoRojoBeneficio:hover {
  background-color: #bf4b3c !important;
}

.desinscribirCiclo,
.desinscribirCiclo:hover {
  margin-top: 10px !important;
  color: #fff !important;
  background-color: #bf4b3c !important;
}

.asignarProfesional,
.asignarProfesional:hover {
  background-color: #3cbf71 !important;
  color: #fff !important;
}

.beneficioUsuario .cardBeneficio {
  padding: 20px;
  min-height: 100px;
}
.beneficioUsuario .cardDivBeneficio {
  font-size: 12px;
  margin-top: 10px;
}
.beneficioUsuario .parrafoBeneficio {
  margin-top: 7px;
}
.beneficioUsuario .labelBeneficio {
  font-weight: bold;
}
.beneficioUsuario .botonInscribirBeneficio {
  font-size: 15px;
  background-color: #3cbf71;
  padding: 7px 20px 7px 20px;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}
.beneficioUsuario .botonDesinscribirBeneficio {
  font-size: 15px;
  background-color: #bf4b3c;
  padding: 7px 20px 7px 20px;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.botonesAlineados {
  float: right !important;
  margin-right: 10px !important;
  box-shadow: none !important;
  color: #fff !important;
  width: auto !important;
}

.menuSeleccionado {
  width: 80px !important;
  margin-top: -7px !important;
}
.menuSeleccionadoRegistrosTelefonos {
  width: 92px !important;
  margin-top: -6px !important;
}

.divBordeTablaHistorialHoras .MuiPaper-root,
.divBordeTablaRegistroCanales .MuiPaper-root,
.divBordeTablaComentarios .MuiPaper-root {
  background-color: transparent !important;
}

.fixedContainer {
  left: 50%;
  width: 100%;
  top: 120%;
  transform: translate(-50%, -50%);
}

.centerImage {
  margin-left: auto !important;
  margin-right: auto !important;
  display: block !important;
}

.alignLabel {
  text-align: center;
  margin-top: 10px;
  font-size: 10px;
}

.divImageUser {
  float: left;
  margin-left: 15px;
}

#vista1ficha:after {
  content: " 😅";
}

/*
.MuiTooltip-tooltip {
  background-color: #e8ecef !important;
  color: black !important;
}
*/

.bordeTablaHistorialHoras {
  border-collapse: separate !important;
  border: 3px solid #eaa028;
  border-radius: 15px;
}

.bordeTablaHistorialHoras .MuiTableCell-head {
  border-bottom: 3px solid #eaa028 !important;
  background-color: #e8ecef !important;
  border-radius: 20px 20px 0px 0px !important;
}

.bordeTablaHorasFuturas {
  border-collapse: separate !important;
  border: 3px solid #eaa028;
  border-radius: 15px;
}

.bordeTablaHorasFuturas .MuiTableCell-head {
  border-bottom: 3px solid #eaa028 !important;
  background-color: #e8ecef !important;
  border-radius: 20px 20px 0px 0px !important;
}

.bordeTablaComentarios {
  border-collapse: separate !important;
  border: 3px solid #eaa028;
  border-radius: 15px;
}

.bordeTablaComentarios .MuiTableCell-head {
  border-bottom: 3px solid #eaa028 !important;
  background-color: #e8ecef !important;
  border-radius: 20px 20px 0px 0px !important;
}

.divBordeTablaComentarios div[class*="Component-horizontalScrollContainer"] table,
.divBordeTablaComentarios div[class*="jss"] table {
  border-collapse: separate !important;
  border: 3px solid #eaa028;
  border-radius: 15px;
}

.divBordeTablaComentarios div[class*="Component-horizontalScrollContainer"] table .MuiTableCell-head,
.divBordeTablaComentarios div[class*="jss"] table .MuiTableCell-head {
  border-bottom: 3px solid #eaa028 !important;
  background-color: #e8ecef !important;
  border-radius: 20px 20px 0px 0px !important;
}

.divBordeTablaRegistroCanales div[class*="Component-horizontalScrollContainer"] table,
.divBordeTablaRegistroCanales div[class*="jss"] table {
  border-collapse: separate !important;
  border: 3px solid #eaa028;
  border-radius: 15px;
}

.divBordeTablaRegistroCanales div[class*="Component-horizontalScrollContainer"] table .MuiTableCell-head,
.divBordeTablaRegistroCanales div[class*="jss"] table .MuiTableCell-head {
  border-bottom: 3px solid #eaa028 !important;
  background-color: #e8ecef !important;
  border-radius: 20px 20px 0px 0px !important;
}

.divBordeTablaHistorialHoras div[class*="Component-horizontalScrollContainer"] table,
.divBordeTablaHistorialHoras div[class*="jss"] table {
  border-collapse: separate !important;
  border: 3px solid #eaa028;
  border-radius: 15px;
}

.divBordeTablaHistorialHoras div[class*="Component-horizontalScrollContainer"] table .MuiTableCell-head,
.divBordeTablaHistorialHoras div[class*="jss"] table .MuiTableCell-head {
  border-bottom: 3px solid #eaa028 !important;
  background-color: #e8ecef !important;
  border-radius: 20px 20px 0px 0px !important;
  padding: 5px;
}

.inputInicio {
  width: 50% !important;
  padding: 12px 20px !important;
  margin: 8px 0 !important;
  display: inline-block !important;
  border: 1px solid #7a7676 !important;
  border-radius: 4px !important;
  box-sizing: border-box !important;
}

.imagenUsuario {
  width: 40px;
  margin-left: 7px;
  margin-bottom: -10px;
  cursor: pointer;
}

.imagenBotonAccion {
  width: 40px;
  margin-right: 7px;
  margin-bottom: -10px;
  float: right;
  cursor: pointer;
}

.primeraImagen {
  margin-left: 50px;
}

.fichaUsuario {
  margin-top: 10px;
}

.middle-content .makeStyles-grid-1 {
  width: unset;
  margin: 0 0px !important;
}

.botonesAlineados {
  color: #fff !important;
}

.buscarUsuario .MuiPaper-root {
  position: initial !important;
}

.escucharAudio .MuiDialog-paperWidthSm {
  max-width: inherit;
  width: 40% !important;
}
.detalleAudio .MuiDialog-paperWidthSm {
  max-width: inherit;
  width: inherit;
}

.beneficioUsuario .MuiDialog-paperWidthSm {
  max-width: inherit;
  width: 70% !important;
}

.cicloUsuario .MuiDialog-paperWidthSm {
  max-width: inherit;
  width: 70% !important;
}

.anularHora .MuiDialog-paperWidthSm {
  max-width: inherit;
  width: 36% !important;
}
.detalleHorasBloqueadas .MuiDialog-paperWidthSm {
  max-width: inherit;
  width: 60% !important;
}
.modalVacio .MuiDialog-paperWidthSm {
  max-width: inherit;
  width: 100% !important;
}
.ultimaSolicitud .MuiDialog-paperWidthSm {
  max-width: inherit;
  width: 90% !important;
}

.agendarHora .MuiDialog-paperWidthSm,
.comentarios .MuiDialog-paperWidthSm,
.modificarUsuario .MuiDialog-paperWidthSm,
.eliminarUsuario .MuiDialog-paperWidthSm,
.detalleAudio .MuiDialog-paperWidthSm,
.ultimaSolicitud .MuiDialog-paperWidthSm,
.cicloUsuario .MuiDialog-paperWidthSm,
.escucharAudio .MuiDialog-paperWidthSm {
  background-image: url("./../img/marca_de_agua20.svg");
  background-repeat: no-repeat;
  background-position: right bottom;
}

.anularHora .MuiDialog-paperWidthSm .MuiDialogContent-root {
  padding: 50px 50px 10px 50px !important;
}

.buscarUsuario .MuiButton-contained,
.cargarUsuarios .MuiButton-textPrimary {
  box-shadow: none !important;
  text-transform: capitalize !important;
  color: #fff !important;
  margin-right: 10px;
  margin-left: 10px;
}

.comentariosUsuario {
  border: 2px solid #eaa028;
  border-radius: 8px;
  padding: 20px;
}

.bordeAudio {
  border: 2px solid #eaa028;
  border-radius: 7px;
  padding: 10px;
}

.modificarUsuario input,
.comentariosUsuario input,
.grupoFamiliarUsuario input {
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  height: 1.1876em;
  margin: 0;
  display: block;
  padding: 6px 0 7px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
  border-bottom: 1px solid #000;
}
.modificarUsuario input:focus,
.comentariosUsuario input:focus,
.grupoFamiliarUsuario input:focus {
  outline: none;
}

.modificarUsuario .MuiButton-textPrimary {
  color: #69b3da !important;
  float: right !important;
}

.botonDialog {
  text-transform: capitalize !important;
  color: #fff !important;
}
.botonDialogCancel {
  text-transform: capitalize !important;
  float: right !important;
  color: #fff !important;
}

.botonSimple {
  color: #dbe2e6 !important;
}

.botonError {
  background-color: #bf5b5b !important;
  color: #fff;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 5px;
}

.buscarUsuario .MuiInput-underline:after {
  border-bottom: 2px solid #69b3da !important;
}

.buscarUsuario .MuiFormLabel-root.Mui-focused {
  color: #69b3da !important;
}

.selectDatosUsuario .MuiSelect-select {
  padding: 12px;
}

.botonAgregarUsuario {
  margin-top: 20px;
}

.datosUsuario {
  margin-top: -45px;
  border-radius: 5px;
  border: #eaa028 2px solid;
  margin-left: 78px;
}
.datosRegistrosCanales {
  margin-top: -85px;
  border-radius: 5px;
  margin-left: 53px;
}

.imagenDatoSeleccionado {
  width: 55px;
  margin-top: 35px;
  margin-left: 25px;
}

.contenidoDatosUsuario {
  padding: 20px 5px 20px 30px;
}

.datosIndividuales {
  padding: 5px !important;
  font-size: 13px;
}

.tituloDatosUsuario {
  font-weight: bold !important;
  font-size: 15px;
  margin-right: 10px;
}

.agendarHora .MuiDialogContent-root {
  min-height: 400px !important;
}

.btnAgregarFamiliar {
  float: right !important;
  color: #fff !important;
  text-transform: capitalize !important;
}

@media screen and (max-width: 40em) {
  .MuiOutlinedInput-root {
    margin-left: 0px;
    margin-bottom: 20px !important;
  }
  .imagenUsuario {
    width: 55px !important;
  }
  .imagenBotonAccion {
    width: 50px !important;
  }
  .primeraImagen {
    margin-left: 20px !important;
  }
  #modalAgendarEspecialidades,
  #modalAgendarAtenciones {
    max-width: 100% !important;
  }
  #modalAgendarBuscar {
    margin-left: 4em !important;
  }
  #modalAgendarTabla {
    overflow-x: scroll !important;
  }
  #modalComentarioTabla {
    overflow-x: scroll !important;
  }
  #modalComentarioComentario,
  #modalComentarioBotones {
    max-width: 100% !important;
  }

  #modalComentarioComentario .MuiInputBase-input {
    width: 300px !important;
  }

  .modalModificarUsuarioSeccion,
  .modalAgregarUsuarioSeccion {
    margin-top: 0px !important;
  }

  .modalModificarUsuarioDatos,
  .modalAgregarUsuarioDatos {
    width: 80% !important;
    margin-top: 0px;
    margin-left: 0px !important;
  }

  #modalModificarUsuarioBtnCancelar {
    margin-left: 5px !important;
  }

  #modalAgregarUsuarioBtnCancelar {
    margin-left: 5px !important;
  }

  .middle-area {
    height: 80% !important;
    margin: 0px 6% !important;
  }

  .perfil,
  .notificacion,
  .menu {
    width: 50px !important;
  }
  .perfil .menu-bars,
  .notificacion .menu-bars,
  .menu .menu-bars {
    padding-left: 1rem !important;
  }

  .select-cesfam {
    width: 130px !important;
  }

  .botonAgregarUsuario {
    float: left !important;
  }

  #modalEliminarUsuario {
    width: 185px !important;
    margin-left: -30px !important;
  }

  #modalEliminarBtnCancelar {
    margin-left: 5px !important;
  }

  #vistaTelefonos {
    overflow-x: scroll !important;
  }
}

@media screen and (max-width: 60em) and (min-width: 40em) {
  .primeraImagen {
    margin-left: 10px !important;
  }
  .agendarHora .MuiTable-root {
    margin-left: -9%;
  }
}

.agendarOtroCesfam {
  background-image: url("./../img/marca_de_agua20.svg");
  background-repeat: no-repeat;
  background-position: right bottom;

  background-color: #e8ecef !important;
  border-radius: 10px !important;
}

.agendarOtroCesfam .MuiGrid-container {
  padding: 30px !important;
}

.MuiAutocomplete-inputFocused {
  border: 0px !important;
}

#telefono_ingresar_solicitud .MuiInput-underline:before {
  border-bottom: none !important;
}
