#tablaBloqueos .MuiPaper-root {
  background-color: rgb(232 236 239 / 20%) !important;
}

#tablaBloqueos div[class*="Component-horizontalScrollContainer"] table,
#tablaBloqueos div[class*="jss"] table {
  border-collapse: separate !important;
  border: 3px solid #eaa028;
  border-radius: 15px;
}

#tablaBloqueos div[class*="Component-horizontalScrollContainer"] table .MuiTableCell-head,
#tablaBloqueos div[class*="jss"] table .MuiTableCell-head {
  border-bottom: 3px solid #eaa028 !important;
  background-color: #e8ecef !important;
  border-radius: 20px 20px 0px 0px !important;
}

.botonesTablaBloq {
  position: relative;
  bottom: -0.2vw;
  float: right;
  z-index: 9;
  display: flex;
}
