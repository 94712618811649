.cargarHorario .MuiButton-contained {
  box-shadow: none !important;
  color: #fff !important;
}

.cargarHorario .horarioCreado {
  border: 1px solid #fff;
  color: #fff !important;
  font-size: 12px;
}

.cargarHorario .horarioBloqueado {
  border: 1px solid #fff;
  color: #fff !important;
  font-size: 12px;
}

.cargarHorario .horarioBloqueado a label {
  color: #fff !important;
}

.modalCopiarSemana .MuiDialog-paperWidthSm {
  background-image: url("./../img/marca_de_agua20.svg");
  background-repeat: no-repeat;
  background-position: right bottom;
}

.porSeleccionar {
  border: 1px black solid;
  padding: 5px 15px !important;
  border-radius: 15px !important;
  cursor: pointer;
  position: relative;
}

.modalHorarioCopiar button {
  color: #fff !important;
  margin-bottom: 20px;
}

.modalCopiarSemana .MuiDialog-paperWidthSm {
  width: 100% !important;
  max-width: 100% !important;
}

.modalCopiarSemana .MuiGrid-grid-md-3 {
  max-width: 100% !important;
}

.btnAccionesCrearHorario {
  color: #fff !important;
  width: 100%;
  margin-bottom: 20px;
}

.btnAccionesDisabledCrearHorario {
  background-color: #807f7e !important;
}

.react-confirm-alert-button-group > button {
  padding: 6px 10px;
}

.contenedorDatos {
  cursor: help;
  display: inline-flex;
  flex-direction: column;
  z-index: 100;
  text-align: center;
}

.cargarHorario .horarioASeleccionar {
  border: 1px solid #fff;
  font-size: 12px;
}

.MuiTableCell-root {
  text-align: center !important;
}

.rc-time-picker {
  width: 115px;
  float: left;
  margin-left: 3px;
}

.imagenBotonReservarHorario {
  width: 40px;
  float: right;
  cursor: pointer;
}

.celdaConImg {
  display: flex !important;
  justify-content: space-around;
  align-items: center;
}

.popupAlerta {
  font-family: Arial, Helvetica, sans-serif;
  width: 400px;
  padding: 30px;
  text-align: left;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgb(0 0 0 / 13%);
  color: #666;
}

.inputTextAgendar {
  width: 100%;
  height: 40px;
  border: 1px solid #ccc !important;
  margin: 8px 0;
  padding: 1vw;
  font-size: 21px;
}

.react-confirm-alert-button-group > button {
  font-size: 18px;
}

.estadoAnulacion {
  color: #fff !important;
  border: 3px #e8ecef solid;
  border-radius: 18px;
  padding: 10px;
  margin: -1vw;
}

.btnAccionesAnulacion {
  width: 3vw;
  cursor: pointer;
}

.inputSelectAgendar {
  width: 25%;
  border: 1px solid #ccc !important;
  margin: 8px 0;
  padding: 10px;
  font-size: 21px;
}

.estadoHora {
  border-radius: 10px;
  height: 1vw;
  width: 1vw;
  position: absolute;
  top: -5px;
  right: -9px;
}

.MuiTableCell-head {
  border-radius: 0px !important;
}

.MuiTableCell-head.cellTermino {
  border-radius: 0 10px 0 0 !important;
}

.MuiTableCell-head.cellInicio {
  border-radius: 10px 0 0 0 !important;
}

.horasHorario {
  position: sticky;
  left: 0px;
  background-color: rgb(232, 236, 239);
  border-right: 3px solid #eaa028;
  z-index: 1;
}

.fechaCalendario {
  background: #3cbf71;
  color: #fff !important;
}

.fechaActivaCalendario {
  background: #4080ff;
  color: #fff !important;
  border: 2px solid #ffffff;
}

.rwdpDayBoxDesktop .rwdp-DayCircle-unavailable-text {
  position: absolute;
  bottom: -36px !important;
}

.autocompleteBuscar {
  padding-right: 10px;
  margin-top: 30px;
}

.semanasHorario {
  position: sticky;
  left: 0px;
  background-color: rgb(232, 236, 239);
  border-bottom: 3px solid #eaa028 !important;
  z-index: 1;
}

.finalSemanasHorario {
  position: sticky;
  left: 0px;
  background-color: rgb(232, 236, 239);
  border-right: 3px solid #eaa028 !important;
  border-bottom: 3px solid #eaa028 !important;
  z-index: 1;
}

.finalSemanasHorarioCell {
  position: sticky;
  left: 0px;
  background-color: rgb(232, 236, 239);
  border-right: 3px solid #eaa028 !important;
  z-index: 1;
}
