.imgNotificacion {
  background-image: url("./../img/imgUpperBar/notificaciones.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  cursor: pointer;
  width: 3vw;
  height: 3vw;
  margin-left: 4vw;
  position: absolute;
}

.imgConfig {
  background-image: url("./../img/imgUpperBar/configurar.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  cursor: pointer;
  width: 3vw;
  height: 3vw;
  margin-left: 1.5%;
  position: absolute;
}

.imgConfigAct {
  background-image: url("./../img/imgUpperBar/configurar.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;

  width: 3vw;
  height: 3vw;
  margin-left: 1.5%;
  position: relative;
}

.imgCesfam {
  background-image: url("./../img/imgUpperBar/cesfam.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  cursor: pointer;
  width: 3vw;
  height: 3vw;
  margin-left: 1.5%;
  position: relative;
}

.divImg {
  width: 68%;
  height: 100%;
  position: relative;
  z-index: 4;
  float: right;
  margin-top: 1vw;
}

.datosUsu {
  color: white;
  font-weight: 600;
  width: 60%;
}
.datosUsu .p1 {
  font-size: 2vw;
}
.datosUsu .p2 {
  font-size: 1.5vw;
}
.datosUsu .p3 {
  font-size: 1.2vw;
}

.btnCasa {
  background-image: url("./../img/home.svg");
  height: 7%;
  background-repeat: no-repeat;
  width: 2.5%;
  background-color: transparent;
  background-position: center;
  position: fixed;
  top: 3%;
  left: 42.5vw;
}

.btnComuna {
  z-index: 5;
  width: 10vw;
  background-color: #09a6d1;
  color: white;
  height: 3vw;
  margin-top: 1%;
  border-radius: 0.3vw;
  margin-left: 1%;
  font-size: 1vw;
  text-align: center;
}

.btnGraficos {
  background-image: url("./../img/imgUpperBar/grafico.svg");
  height: 7%;
  background-repeat: no-repeat;
  width: 2.5%;
  background-color: transparent;
  background-position: center;
  position: fixed;
  top: 3%;
  left: 56vw;
}

.bComuna {
  font-size: 1vw;
  text-align: center;
}

.divBtnUpper {
  position: fixed;
  width: 100%;
  height: 20%;
  top: 0;
  left: 42%;
}

.lista {
  position: fixed;
}

.divListaCesfam {
  background-color: rgb(255, 153, 0);
  display: flex;
  border-radius: 0.3vw;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  right: unset;
  top: 7vh;
  width: 26vh;
  position: relative;
  height: 100%;
}

.upperselectcesfam {
  z-index: 3;
  position: absolute;
  right: -8%;
  width: 40%;
  /* top: 1vh; */
  display: flex;
}

.selectCesfams {
  background-color: transparent;
  color: white;
  border: 0.16vw solid white;
  border-radius: 1vw;
  cursor: pointer;

  width: 90%;
  font-size: 2vh;
  height: 65%;
}

.optionsCesfam {
  background-color: rgb(255, 153, 0);
}

.divConfig {
  background-color: rgb(255, 153, 0);
  height: 10vh;
  border-radius: 0.3vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 99;

  top: 7vh;
  width: 26vh;
  right: unset;
  position: relative;
  right: 9vh;
}

.butonCambioContr {
  width: 10vw;
  border-radius: 1vw;
  background: transparent;
  border: 0.16vw solid white;
  color: white;
  margin-top: 0.8vw;
  margin-bottom: 2%;
  height: 3vh;
  font-size: 0.9vw;
  cursor: pointer;
  box-shadow: 0px 5px 5px -4px rgba(0, 0, 0, 0.35);
}

.butonCerrarSesion {
  width: 10vw;
  border-radius: 1vw;
  background: transparent;
  border: 0.16vw solid white;
  color: white;
  margin-top: 2%;
  margin-bottom: 2%;
  height: 3vh;
  font-size: 0.9vw;
  cursor: pointer;
  box-shadow: 0px 5px 5px -4px rgba(0, 0, 0, 0.35);
}

.divNoti {
  background-color: white;
  border-radius: 0.3vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 3%;
  z-index: 99;
  overflow-y: scroll;
  height: 23vh;
  top: 7vh;
  width: 26vh;
  right: 20vh;
  position: relative;
  border: #ea9f29 solid;
}

.labelNot {
  color: #ea9f29;
  margin-left: 5%;
  margin-top: 2%;
  font-size: 0.9vw;
  font-weight: bold;
}

.linkButn {
  width: 100%;
  margin-top: 1vh;
}

.pRol {
  font-size: 70%;
}

.h1noty {
  font-size: 0.9rem;
  display: flex;
  margin-left: 4%;
  margin-top: 1%;
  color: white;
  text-align: start;
}

.div2noti {
  border-radius: 5px;
  width: 88%;
  margin-left: 6%;
  margin-top: 2%;
  margin-bottom: 2%;
  background-color: rgb(255, 153, 0);
}
.h2noty {
  font-size: 0.8rem;
  display: flex;
  margin-left: 4%;
  margin-top: 1%;
  color: white;
}
.labelNoty {
  font-size: 0.8rem;
  display: flex;
  margin-left: 4%;
  margin-top: 5%;
  width: 92%;
  margin-bottom: 5%;
  color: white;
}

.spanNotSin {
  background: red;
  color: white;
  font-size: 0.7vw;
  width: 1vw;
  height: 2vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1vw;
  position: absolute;
  left: 86%;
  top: 5%;
}

.cerrarbtn {
  position: absolute;
  right: 10%;
  background: transparent;
  color: white;
  font-size: 1.4vw;
}

.divspancomuna {
  position: absolute;
  background: #09a6d1;
  top: 2vh;
  width: 10vw;
  left: 45.5vw;
  height: 5vh;
  border-radius: 0.3vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.espanComuna {
  color: white;
  font-size: 1.2vw;
}

.titulobajoComuna {
  position: absolute;
  top: 3vh;
  left: 22.1vw;
  display: flex;
  width: 43vw;
  justify-content: center;
  z-index: 5;
}
.titulobajoMasComunas {
  position: absolute;
  top: 0.7vw;
  left: 29.1vw;
  display: flex;
  width: 43vw;
  justify-content: center;
  z-index: 5;
}

@media (max-width: 1620px) {
  .datosUsu {
    width: 100%;
  }

  .divImg {
    width: 100%;
    margin-top: 1vw;
  }

  .divListaCesfam {
    right: 5.5vw;
    top: 7vh;
    width: 14vw;
    position: relative;
    height: 100%;
  }

  .selectCesfams {
    width: 90%;
    font-size: 2vh;
    height: 65%;
  }

  .divConfig {
    top: 4vw;
    width: 26vh;
    position: relative;
    left: -5.5vw;
  }

  .butonCambioContr {
    font-size: 2vh;
    width: 90%;
  }

  .butonCerrarSesion {
    font-size: 2vh;
    width: 90%;
  }

  .divNoti {
    top: 7vh;
    width: 26vh;
    right: 20vh;
    position: relative;
  }

  .labelNot {
    font-size: 2vh;
  }

  .imgNotificacion {
    margin-left: 3.5vw;
  }
}
