.upper-area {
  z-index: 45;
  position: relative;
  background-image: url("./../img/banner6.png");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.imagen-cesfam {
  float: left;
  margin-top: -1vw;
}

.texto-cesfam {
  color: white;
  font-weight: 500;
  float: left;
  margin-left: 10px;
  font-size: 2.2vh;
}

.upper-logo {
  width: 13%;
  position: absolute;
  left: 1vw;
  top: 1vh;
}

.upper-nombre-herramienta {
  font-size: 2.5vw;
  color: #fff;
  margin-top: 3vw;
}

.upper-select-cesfam {
  z-index: 20;
  position: absolute;
  right: -10%;
  height: 77%;
  width: 40%;
  margin-top: 2.3%;
}

.select-cesfam {
  margin-left: 60px;
  width: 170px;
  padding: 10px;
  border-radius: 30px;
  color: #fff;
  background-color: #1691c1;
  border-color: #1691c1;
  outline: none;
}

.particles {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.sideBarVisualizar {
  right: 124px !important;
  transition: right 1.3s;
}

@media (max-width: 1450px) {
  .sideBar_liMenu_button {
    height: 24px;
    width: 169px;
    border-bottom: 1px solid white;
    border-radius: 3px;
    color: white;
    margin-bottom: 2px;
    background-color: #09a6d1;
    font-size: 13px;
  }

  .sideBar_liMenu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 9px;
    position: relative;
    right: 230px;
  }

  .sideBar_Menu {
    display: flex !important;
    position: relative;
    right: 0px;
    height: 14vh;
    margin-bottom: unset;
    border-radius: unset;
  }
}

.botonesSideBar {
  position: relative;
  right: -15vw;
  display: none;
}

.botonesSideBarMost {
  right: 0px !important;
}

.divPrincipalChico {
  position: fixed;
  top: 17%;
  right: -126px;
  background-color: transparent;
  height: 70%;
  width: 8%;
  z-index: 42;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.liMenuChico {
  margin-right: 124px;
}

.liMenu2Chico {
  margin-right: 124px;
}
