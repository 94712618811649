.imagenUsuario {
  margin-left: 15px;
  margin-bottom: -10px;
  cursor: pointer;
  margin-left: auto !important;
  margin-right: auto !important;
  display: block !important;
}
.img_tam {
  width: 5.2vw !important;
}
.img_tam_2 {
  width: 6vw !important;
}

.imagenUsuarioSeleccionado_2 {
  width: 6.3vw !important;
  margin-top: 0px !important;
  cursor: pointer;
}
.imagenUsuarioSeleccionado {
  width: 5.5vw !important;
  margin-top: 0px !important;
  cursor: pointer;
}

.tituloDatosUsuario {
  font-weight: bold !important;
  font-size: 16px;
  margin-right: 10px;
}

.label_11 {
  font-size: 11px !important;
}
.label_13 {
  font-size: 13px !important;
}
.label_16 {
  font-size: 16px !important;
}
.label_20 {
  font-size: 20px !important;
}

.seleccioonTamanio {
  width: 2.6vw;
  position: absolute;
  height: 15vh;
  background: rgb(234, 159, 41);
  left: -3.8%;
  top: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

.imagenDatos {
  width: 7.5vw;
  margin-top: 15px;
  margin-left: 0.3vw;
}
.imagenDatosPequeño {
  width: 5.5vw;
  margin-top: 15px;
  margin-left: 0.3vw;
}

.tablaDatosUsuario {
  margin-top: -5.5vw;
  border-radius: 5px;
  border: #eaa028 4px solid;
  margin-left: 7.7vw;
  width: 105%;
  height: 44vh;
  overflow-y: scroll;
}
.tablaDatosUsuario_peque {
  margin-top: -4.5vw;
  border-radius: 5px;
  border: #eaa028 4px solid;
  margin-left: 5.7vw;
  width: 105%;
  height: 44vh;
  overflow-y: scroll;
}

.datosIndividuales {
  padding: 0.6vw !important;
  font-size: 13px;
  max-width: 50% !important;
}

.imagenDatos_GrupoFamiliar {
  width: 6.5vw;
  margin-left: 2vw;
}
.imagenDatos_GrupoFamiliar_pequenio {
  width: 4.5vw;
  margin-left: 1.3vw;
}
.imagenDatos_telefono_pequenio {
  width: 4.5vw;
  margin-left: 3.8vw;
}

.icono_grande {
  width: 3.7vw;
  margin-right: 7px;
  margin-bottom: 0px;
  float: right;
  cursor: pointer;
  margin-left: 4px;
}
.icono_pequenio {
  width: 2.6vw;
  margin-right: 7px;
  margin-bottom: 0px;
  float: right;
  cursor: pointer;
  margin-left: 4px;
}

.tablaGrupoFamiliar {
  border: 4px solid rgb(234, 160, 40);
  margin-left: 8.6vw;
  margin-top: -5vw;
  border-radius: 5px;
}

.tablasTelefonosP {
  margin-top: -5.8vw;
  border-radius: 5px;
  margin-left: 6.3vw;
}
.tablasTelefonosG {
  margin-top: -7.9vw;
  border-radius: 5px;
  margin-left: 5.8vw;
}

.bordeTablaTelefono {
  border-collapse: separate !important;
  border: 5px solid #eaa028;
  border-radius: 15px;
}

.imagenBotonAccion_p {
  width: 3vw;
  margin-right: 7px;
  margin-bottom: 0px;
  float: right;
  cursor: pointer;
  margin-left: 4px;
}
.imagenBotonAccion_g {
  width: 4vw;
  margin-right: 7px;
  margin-bottom: 0px;
  float: right;
  cursor: pointer;
  margin-left: 4px;
}

/* PROFESIONAL */

.buscarProfesional {
  display: block !important;
}
.datosProfesional {
  margin-top: -4vw;
  border-radius: 5px;
  border: #eaa028 4px solid;
  margin-left: 6vw;
  height: auto;
  min-height: 10vw;
  width: auto;
}
.datosProfesional_P {
  margin-top: -4vw;
  border-radius: 5px;
  border: #eaa028 4px solid;
  margin-left: 6vw;
  height: auto;
  min-height: 10vw;
  width: auto;
}

.imagenBotonAccion_G {
  width: 3.5vw;
  margin-right: 7px;
  margin-bottom: -10px;
  float: right;
  cursor: pointer;
}
.imagenBotonAccion_P {
  width: 2.5vw;
  margin-right: 7px;
  margin-bottom: -10px;
  float: right;
  cursor: pointer;
}

.img_tam_pro {
  width: 5.2vw !important;

  margin-left: 1vw;
}
.img_tam_2_pro {
  width: 6vw !important;
}

/* BLOQUE HORAS */

.btnDisabled {
  color: #000 !important;
  background-color: #9c9c9c;
  border-radius: 1vw !important;
  border-color: #9c9c9c;
  margin-bottom: 5px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid #9c9c9c;
  width: 4vw;
  height: 4vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn {
  color: #000 !important;
  margin-bottom: 5px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  width: 4vw;
  height: 4vh;
  border-radius: 1vw !important;
  background-color: rgb(62, 195, 62);
  border-color: rgb(62, 195, 62);
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn_peque {
  width: 3vw !important;
  height: 3vh !important;
}

.lapizAgenda {
  width: 2.5vw;
}
.lapizAgenda_peque {
  width: 1.5vw;
}
.SelectFecha {
  padding: 5px;
  margin-top: 9px;
  margin-left: 20px;
  width: 22%;
  height: 80%;
  border: solid 1px #bfc3c6;
  border-radius: 3px;
  background: transparent;
}

.SelectFecha:hover {
  border: solid 1px black;
  cursor: pointer;
}

.icon_registro {
  width: 2.5vw;
}
.icon_registro_peque {
  width: 2vw;
}
