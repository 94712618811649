#container {
  display: flex;
  height: auto;
  width: 80%;
  padding-left: 5%;
}

.contenedorCircular {
  padding: 1vw;
  border: 2px solid #000;
  border-radius: 10vw;
  width: 5vw;
  display: flex;
  justify-content: center;
  font-size: 2vw;
  float: right;
}
