* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  border: none;
}

html {
  overflow: hidden;
}

.app {
  background-color: #e8ecef;
  display: grid;
  height: 100vh;
  margin: 0%;
  position: relative;
  grid-template-columns: 100%;
  grid-template-rows: 1.9fr 7fr 1.5fr;
  grid-template-areas:
    "upper_area"
    "middle-area"
    "bottom_area";
}

.middle-area {
  margin: 0px 3%;
  background-color: transparent;
}

a {
  color: inherit;
  text-decoration: none;
}

.middle-area {
  position: absolute;
  height: 72%;
  z-index: 20;
  width: 86%;
}

.content-breadcumb {
  margin: 0;
  font-size: 0.8rem;
  color: #4c83a0;
}

.content-titulo {
  font-size: 2.5vw;
  color: #fff;
  margin-top: 3vw;
}

.middle-content {
  margin-top: 20px;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 92%;
}

.MuiDialog-root {
  z-index: 90 !important;
}

.MuiBackdrop-root {
  background-color: rgb(25 25 25 / 90%) !important;
}
.MuiPaper-elevation24 {
  box-shadow: 0 20px 75px rgb(0 0 0 / 13%) !important;
  border-radius: 10px !important;
}

/* width */
::-webkit-scrollbar {
  width: 12px;
}

.MuiPaper-elevation24 {
  box-shadow: none !important;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: none;
  border-radius: 5px;
}

/* Handle 
::-webkit-scrollbar-thumb {
  background: #6cb5dd;
  border-radius: 5px;
}
*/

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #6cb5dd;
}

.react-confirm-alert-overlay {
  background: rgb(45 45 45 / 90%) !important;
}

.MuiTableCell-head {
  border-bottom: 3px solid #eaa028 !important;
  background-color: #e8ecef !important;
  border-radius: 20px 20px 0px 0px !important;
}

.floatRigth {
  float: right !important;
}

.MuiDialog-paperWidthSm .MuiDialogContent-root {
  padding: 50px !important;
}

.cellInicio {
  border-radius: 10px 0 0 0;
}
.cellTermino {
  border-radius: 0 10px 0 0;
}

.react-confirm-alert-button-group {
  display: inherit !important;
  padding-bottom: 20px !important;
}

.react-confirm-alert-button-group > button {
  float: right !important;
}

.bordeTabla {
  border-collapse: separate !important;
  border: 3px solid #eaa028;
  border-radius: 15px;
}

.bordeTablaCovid {
  border-collapse: separate !important;
  border: 3px solid #05477b;
  border-radius: 15px;
}

.bordeTablaCovid .MuiTableCell-head {
  border-bottom: 3px solid #05477b !important;
  background-color: #e8ecef !important;
  border-radius: 20px 20px 0px 0px !important;
}

.MuiPaper-elevation2 {
  box-shadow: none !important;
}

.MuiPaper-root {
  background-color: #e8ecef !important;
}

.divBordeTabla div[class*="Component-horizontalScrollContainer"] table {
  border-collapse: separate !important;
  border: 3px solid #eaa028;
  border-radius: 15px;
}

.divBordeTabla div[class*="Component-horizontalScrollContainer"] table tr:nth-child(odd) th:first-child {
  border-radius: 10px 0 0 0;
}
.divBordeTabla div[class*="Component-horizontalScrollContainer"] table tr:nth-child(odd) th:last-child {
  border-radius: 0 10px 0 0;
}

.alertaPersonalizada {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
}

.alertaPersonalizada button,
.alertaPersonalizada button:hover {
  float: right;
  background-color: #eaa028;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  border: inherit;
  margin-left: 10px;
  margin-top: 20px;
  position: relative;
}

.fondoVerde,
.fondoVerde:hover {
  background-color: #3cbf71 !important;
  color: white !important;
}

.fondoNaranjo,
.fondoNaranjo:hover {
  background-color: #eaa028 !important;
}
.fondoRojo,
.fondoRojo:hover {
  background-color: #eaa028 !important;
}

.fondoAzul,
.fondoAzul:hover {
  background-color: #1491c1 !important;
  color: white !important;
}

.btnAceptar {
  background-color: #3cbf71 !important;
}

.btnCancelar {
  background-color: #eaa028 !important;
}

.notification-success,
.notification-error,
.notification-warning,
.notification-info {
  background-color: transparent !important;
  box-shadow: 0px 0px 5px #000 !important;
  background-image: url("./assets/img/marca_agua_notificacion.svg"), linear-gradient(90deg, rgba(10, 70, 119, 1) 0%, rgba(41, 103, 150, 1) 33%, rgba(71, 135, 179, 1) 61%, rgba(149, 216, 255, 1) 100%);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom right;
}

.notification-error {
  border: 1px solid red;
}
.notification-success {
  border: 1px solid green;
}

.notification-info {
  border: 1px solid blue;
}

.notification-warning {
  border: 1px solid yellow;
}

.notification {
  border-radius: 15px !important;

  opacity: 1 !important;
}

.notification-container {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 35px;
  z-index: 999999;
  width: 320px;
  padding: 5px !important;
}

.MuiTooltip-tooltip {
  background-color: #dcdcdc !important;
  color: black !important;
}

.react-confirm-alert-body > h1 {
  font-size: 18px !important;
  margin-bottom: 15px !important;
  color: #252525 !important;
}

.react-confirm-alert-body {
  color: #252525 !important;
}

#demo-popup-popover {
  z-index: 25 !important;
}

.info-titulo {
  font-size: 14px;
  margin-bottom: 5px;
  color: #737373;
  margin-left: 10px;
}

.alignCenter {
  text-align: center;
}

.background-rojo {
  background-color: #bf4b3c !important;
}

iframe {
  display: none;
}

.scroll-container {
  padding-bottom: 180px;
  overflow-y: scroll;
  max-height: 600px;
}

#label-preguntas-triage {
  font-size: 22px;
}

.mensajes-sin-leer {
  position: absolute;
  background-color: red;
  border-radius: 25px;
  padding: 5px;
  font-size: 12px;
  color: white;
  margin-left: -20px;
  min-width: 24px;
  text-align: center;
  font-weight: bold;
}

._2sm4earticle {
  height: 90%;
}

._2sm4e {
  height: 100%;
}

._14898 {
  height: 100%;
}

._2HbZ0 {
  display: grid;
  grid-auto-rows: minmax(0, 1fr) !important;
  grid-template-rows: none !important;
  grid-gap: 0.5rem !important;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  padding: 0.5rem;
}

.solo_2vumQ {
  width: 100%;
  height: 100%;
  display: initial !important;
  padding: 0;
}

._3MB5b,
._213aF,
._1smc2 {
  display: none !important;
}

.video1 {
  width: 20% !important;
  height: 20% !important;
  position: absolute;
  z-index: 99;
  right: 0px;
  padding: 0.5rem;
  top: 0;
}
