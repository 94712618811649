.boton {
  color: #fff;
  padding: 9px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  margin-right: 20px;
  background-color: #eaa028 !important;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icono {
  width: 30px;
  cursor: pointer;
}

.inputText {
  padding: 6px 0 7px;
  border: 0;
  border-bottom: 1px solid #000;
  background: none;
  font-size: 1rem;
  width: 100%;
}

.inputTextErr {
  padding: 6px 0 7px;
  border: 0;
  border-bottom: 1px solid red;
  background: none;
  font-size: 1rem;
  width: 100%;
}

.GridModalFuncionario {
  padding: 0 !important;
  display: flex;
  margin-bottom: 30px;
}

.mensajeErr {
  color: red;
  font-size: 0.8rem;
  padding: 10px 0;
}

.multiselect .dropdown-container {
  border: none;
  border-bottom: black 1px solid;
  background: transparent;
  border-radius: 0;
}

.limites_autorizados {
  font-size: 1vw;
  text-align: center;
  color: white;
  width: 15vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.2vw;
  margin-right: 1vw;
  height: 2vw;
}

.descargarExcelAcceso {
  float: right;
  margin-right: 2vw;
  background: #eaa028;
  padding: 0 1vw;
  color: white;
  border-radius: 0.2vw;
  cursor: pointer;
  width: 13vw;
  height: 3vw;
  font-size: 1vw;
}
