.btnAcciones {
  float: left;
  color: #fff;
  padding: 9px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  margin-right: 20px;
  background-color: #eaa028 !important;
}

.inputText {
  padding: 10px;
  border: 0;
  border-bottom: 1px solid #000;
  background: none;
  font-size: 1vw;
}

.inputTextArea {
  width: 100%;
  height: 100%;
  padding: 10px;
  border: 1px solid #000;
  border-radius: 5px;
  background: none;
  font-size: 1vw;
}

.divBordeTablaAccesos {
  position: relative;
}

.divBordeTablaAccesos div[class*="Component-horizontalScrollContainer"] table,
.divBordeTablaAccesos div[class*="jss"] table {
  border-collapse: separate !important;
  border: 3px solid #eaa028;
  border-radius: 15px;
}

.divBordeTablaAccesos div[class*="Component-horizontalScrollContainer"] table .MuiTableCell-head,
.divBordeTablaAccesos div[class*="jss"] table .MuiTableCell-head {
  border-bottom: 3px solid #eaa028 !important;
  background-color: #e8ecef !important;
  border-radius: 20px 20px 0 0 !important;
}

.btnAgregarAcceso {
  position: relative;
  float: right;
  z-index: 9;
  width: fit-content;
  font-size: 20px;
}

.divBordeTablaAccesos .MuiToolbar-root {
  padding: 0 !important;
}

.textosTitulos {
  font-size: 20px !important;
  margin-bottom: 10px !important;
  font-weight: bold !important;
}

.inputArchivos {
  padding: 10px;
  border: 1px solid #000;
  background: none;
  font-size: 1vw;
  border-radius: 5px;
}

.botonesOpc {
  width: 100%;
}

.botonesOpc .MuiGrid-item {
  text-align: center;
}

.botonesOpc .MuiFormControlLabel-root {
  margin: 0;
}

.selectT {
  padding: 3px;
  border: 0;
  border-bottom: 1px solid #000;
  background: none;
  font-size: 1vw;
  width: 100%;
}

.btnsNormal {
  float: left;
  color: #fff;
  padding: 9px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  margin-right: 20px;
  background-color: #eaa028 !important;
  font-size: 22px;
}

.botonesTablas {
  width: 3vw;
  cursor: pointer;
}

.divBordeTablaAccesos .MuiTableCell-root {
  text-align: center !important;
  font-size: 1vw !important;
}

.middle-contentAdmin {
  margin-top: 20px;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 70%;
}

.botonAccionActivo {
  background-color: #dd8114 !important;
}

.opcionesHabilitadas .MuiDialog-paperWidthSm {
  width: 50%;
}

.btnsAccionesPanel {
  color: #fff;
  padding: 9px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  margin-right: 6vw;
  background-color: #eaa028 !important;
  font-size: 30px;
}

.videosPantalla .MuiDialog-paperWidthSm {
  width: 60%;
}
