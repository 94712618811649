.botonesAlineados {
  float: right !important;
  margin-right: 27px !important;
  box-shadow: none !important;
  color: #fff !important;
  width: auto !important;
}

.modalTelefonos .MuiDialog-paperWidthSm {
  background-image: url("./../img/marca_de_agua20.svg");
  background-repeat: no-repeat;
  background-position: right bottom;
}

.agregarCaso .MuiDialog-paperWidthSm {
  max-width: 60%;
}

.plantilla-chat {
  border: 1px solid black;
  border-radius: 10px;
  padding: 10px;
  margin-top: 15px;
}
.plantilla-comentarios-demanda {
  border: 2px solid #eaa028;
  border-radius: 10px;
  padding: 10px;
  margin-top: 15px;
}

.estiloSelect {
  padding: 6px;
  border: none;
  background-color: #e8ecef;
  border-bottom: 1px solid black;
  margin-top: 5px;
}

.mensajeGlobal .MuiDialog-paperWidthSm,
.plantillasMensajeGlobal .MuiDialog-paperWidthSm,
.agregarObservacion .MuiDialog-paperWidthSm {
  max-width: 60%;
}

.buscarUsuarioGestion .MuiDialog-paperWidthSm {
  max-width: 90%;
}

.riesgo {
  border-radius: 5px;
  margin-top: 8px;
}

.botonesDemanda {
  background-color: #fff !important;
  color: #69b3da !important;
}

.inputInicio {
  width: 50% !important;
  padding: 12px 20px !important;
  margin: 8px 0 !important;
  display: inline-block !important;
  border: 1px solid #7a7676 !important;
  border-radius: 4px !important;
  box-sizing: border-box !important;
}

.smallMedIcon {
  width: 30px;
  cursor: pointer;
  margin-top: 10px;
  margin-left: 3px;
}

.imagenUsuario {
  width: 40px;
  margin-left: 15px;
  margin-bottom: -10px;
}

.primeraImagen {
  margin-left: 15px;
}

.fichaUsuario {
  margin-top: 10px;
}

.middle-content .makeStyles-grid-1 {
  width: unset;
  margin: 0 0px !important;
}

.botonesAlineados {
  color: #fff !important;
}

.buscarUsuario .MuiPaper-root {
  position: initial !important;
}

.agendarHora .MuiDialog-paperWidthSm {
  width: 70% !important;
  max-width: inherit !important;
}
.modalTelefonos .MuiDialog-paperWidthSm {
  width: 90% !important;
  max-width: inherit !important;
}

.buscarUsuario .MuiButton-contained,
.cargarUsuarios .MuiButton-textPrimary {
  box-shadow: none !important;
  color: #fff !important;
  text-transform: capitalize !important;
  margin-right: 10px;
  margin-left: 10px;
}

.modificarUsuario input,
.comentariosUsuario input,
.grupoFamiliarUsuario input {
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  height: 1.1876em;
  margin: 0;
  display: block;
  padding: 6px 0 7px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
  border-bottom: 1px solid #000;
}
.modificarUsuario input:focus,
.comentariosUsuario input:focus,
.grupoFamiliarUsuario input:focus {
  outline: none;
}

.modificarUsuario .MuiButton-textPrimary {
  color: #fff !important;
  float: right !important;
  text-transform: capitalize !important;
}

.botonDialog {
  float: right !important;
  color: #fff !important;
  text-transform: capitalize !important;
}

.botonSimple {
  color: #fff !important;
  text-transform: capitalize !important;
}

.botonError {
  background-color: #bf5b5b !important;
  color: #fff;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 5px;
}

.buscarUsuario .MuiInput-underline:after {
  border-bottom: 2px solid #69b3da !important;
}

.buscarUsuario .MuiFormLabel-root.Mui-focused {
  color: #69b3da !important;
}

.imagenBotonAccion {
  width: 40px;
  margin-right: 7px;
  margin-bottom: 0px;
  float: right;
  cursor: pointer;
  margin-left: 4px;
}

.mediumIcon {
  width: 35px;
  float: inherit;
  cursor: pointer;
}

.smallIcon {
  width: 22px;
  margin-right: 7px;
  margin-bottom: -6px;
  cursor: pointer;
  margin-left: 8px;
}

.imagenBotonIconoAccion {
  width: 40px;
  margin-right: 7px;
  margin-bottom: -10px;
  float: right;
  cursor: pointer;
  margin-left: 8px;
}

.imagenBotonAccionMenu {
  width: 40px;
  margin-right: 7px;
  margin-bottom: -10px;
  float: left;
  cursor: pointer;
  margin-left: 8px;
}

.btnAgregarCaso {
  float: right;
  color: #fff;
  padding: 7px 20px 7px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.tablaObservaciones {
  border-collapse: separate !important;
  background-color: white;
  border: 1px solid #000;
  border-radius: 15px;
  font-size: 13px;
}

.tablaObservaciones thead,
.tablaObservaciones th {
  border-radius: 30px;
  background-color: #fff !important;
  border-bottom: inherit;
}
.tablaObservaciones thead {
  border-bottom: 1px solid #000 !important;
}

.tablaGeneralGestion .MuiTableCell-root {
  vertical-align: top !important;
}

.tablaGeneralGestion .cellHead {
  border-bottom: 1px solid black;
}

.btnUltimaObservacion {
  background-color: #eaa028;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.inputSexo {
  padding: 0 0px !important;
}

.bordeCasoDerivado {
  border-left: 5px solid #f70000;
}
.bordeCasoDerivadoUrgente {
  border-left: 5px solid #f70000;
  border-top: 5px solid #f70000;
}

.alertaHTML .MuiDialog-paperWidthSm {
  max-width: inherit;
  width: 80% !important;
}
.casosRegistrados .MuiDialog-paperWidthSm {
  max-width: inherit;
  width: 80% !important;
}
.dialog-cargando .MuiDialog-paperWidthSm {
  background-color: transparent !important;
}
.botonNotificacion {
  background-color: #eaa028;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}
.smallIconRut {
  width: 17px;
  margin-right: 7px;
  margin-bottom: -3px;
  cursor: pointer;
  margin-left: 8px;
}

.observaciones {
  padding: 10px;
  border: 1px solid #eaa028;
  border-radius: 10px;
  margin-top: 10px;
  font-size: 12px;
  min-width: 120px;
}

.agregarCaso .MuiOutlinedInput-input {
  padding: 7.5px 14px;
}

.camposAgendaGestion {
  padding: 6px;
  border-radius: 5px;
}

.badge-mensajes-no-leidos {
  background-color: red;
  color: white;
  padding: 5px 9px 5px 9px;
  border-radius: 15px;
  position: relative;
  top: -45px;
  left: -15px;
}

.input-buscar-usuario {
  border: none;
  border-bottom: 1px solid black;
  width: 200px;
  padding: 5px;
  background-color: #e8ecef;
}
