@keyframes example {
  0% {
    left: 2000px;
  }

  100% {
    left: 20vw;
  }
}

.tooltip {
  background-color: darkgray;
  font-size: 10%;
}

.botonesReservas {
  background-color: transparent;
  font-size: 1vw;
  text-align: center;
  margin-right: 3%;
  color: "white";
}
.botonesReservas:hover {
  background-color: rgb(112, 152, 152);
}

.transicion {
  animation-name: example;
  animation-duration: 1.5s;
}

.color1 {
  background: linear-gradient(to bottom, #e08314, #eaa028, #fcb960);
  font-size: 1vw;
  text-align: center;
  margin-right: 3%;
  color: white;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}
.color2 {
  background: linear-gradient(to bottom, #dd1f95, #ef4597, #ff7dc1);
  font-size: 1vw;
  text-align: center;
  margin-right: 3%;
  color: white;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}
.color3 {
  background: linear-gradient(to bottom, #1fa389, #30b59b, #5dd1bd);
  font-size: 1vw;
  text-align: center;
  margin-right: 3%;
  color: white;
  padding: 10px;
  border-radius: 10px;
}
.color4 {
  background: linear-gradient(to bottom, #143275, #225093, #3675c1);
  font-size: 1vw;
  text-align: center;
  margin-right: 3%;
  color: white;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}
.color5 {
  background: linear-gradient(to bottom, #09a6d1, #23bae8, #64ddf9);
  font-size: 1vw;
  text-align: center;
  margin-right: 3%;
  color: white;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}
.color6 {
  background: linear-gradient(to bottom, #6e45af, #8156c1, #9b76e2);
  font-size: 1vw;
  text-align: center;
  margin-right: 3%;
  color: white;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}
.color7 {
  background: linear-gradient(to bottom, #40b56f, #63c189, #79d89f);
  font-size: 1vw;
  text-align: center;
  margin-right: 3%;
  color: white;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}
.color8 {
  background: linear-gradient(to bottom, #d8275e, #f45175, #ff7b9e);
  font-size: 1vw;
  text-align: center;
  margin-right: 3%;
  color: white;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}
.color9 {
  background: linear-gradient(to bottom, #d8b41d, #e8c42a, #f7d857);
  font-size: 1vw;
  text-align: center;
  margin-right: 3%;
  color: white;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}
