.reserved {
    background-color: #1c5a36 !important;
    color: white;
    border-radius: 5px;
    padding: 5px
}

.nulld {
    background-color: #d8ca0c !important;
    color: black;
    border-radius: 5px;
    padding: 5px
}

.free {
    background-color: #26a872 !important;
    color: white;
    border-radius: 5px;
    padding: 5px
}