/* .divPrincipal {
  position: fixed;
  top: 0%;
  right: -0.2%;
  background-color: transparent;
  height: 100%;
  width: 8%;
  z-index: 42;
  display: flex;
  flex-direction: column;
  justify-content: center;
} */

.divPrincipal {
  position: fixed;
  top: 17%;
  right: -0.2%;
  background-color: transparent;
  height: 70%;
  width: 8%;
  z-index: 42;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.liside {
  display: flex;
  right: -1%;
  height: 5vh;
  margin-bottom: 3%;
}

.liside a {
  display: flex;
  position: absolute;
  margin-bottom: 3%;
  background-color: #09a6d1;
  right: -0.1vw;
  border-radius: 0.4vw;
  width: 180px;
  transition: right 0.8s;
  height: 4.5vh;
}

.aSide {
  display: flex;
  position: absolute;
  margin-bottom: 10%;
  background-color: #09a6d1;
  right: -0.1vw;
  border-radius: 1vw;
  width: 9vw;
  transition: right 0.8s;
  height: 5vh;
}
.aSideActivo {
  display: flex;
  position: absolute;
  margin-bottom: 10%;
  background-color: #09a6d1;
  right: -0.3vw;
  border-radius: 1vw;
  width: 9vw;
  transition: right 0.8s;
  height: 5vh;
}

.selectSide {
  background-color: #09a6d1;
  color: white;
  cursor: pointer;
  white-space: break-spaces;
  font-size: 16px;
  width: 60%;
}

.selectSideGrande {
  background-color: #09a6d1;
  color: white;
  cursor: pointer;
  white-space: break-spaces;
  font-size: 13px;
  width: 60%;
}

.selectSideImg {
  padding: 5px;
  width: 30%;
}

.selectSideGrande1 {
  background-color: #09a6d1;
  color: white;
  cursor: pointer;
  white-space: break-spaces;
  font-size: 14px;
  width: 60%;
}

.aSide1 {
  display: flex;
  position: absolute;
  margin-bottom: 10%;
  background-color: #09a6d1;
  right: -0.3vw;
  border-radius: 1vw;
  width: 9vw;
  transition: right 0.8s;
  height: 5vh;
}

.aSide1Activo {
  display: flex;
  position: absolute;
  margin-bottom: 10%;
  background-color: #09a6d1;
  right: -0.3vw;
  border-radius: 1vw;
  width: 9vw;
  transition: right 0.8s;
  height: 5vh;
}

.imgPaciente {
  font-size: 1.4vw;
  margin-left: 1vw;
  margin-top: 0.6vh;
}

.pacSpan {
  margin-left: 1vw;
  margin-top: 5%;
  color: white;
  font-size: 1vw;
}

.aSidePac {
  display: flex;
  position: absolute;
  margin-bottom: 10%;
  background-color: #09a6d1;
  right: -7.6vw;
  border-radius: 1vw;
  width: 11vw;
  transition: right 0.8s;
  height: 4vh;
}

.aSidePacActivo {
  display: flex;
  position: absolute;
  margin-bottom: 10%;
  background-color: #09a6d1;
  right: -18%;
  border-radius: 1vw;
  width: 11vw;
  transition: right 0.8s;
  height: 4vh;
}

.aSide2 {
  display: flex;
  position: absolute;
  margin-bottom: 10%;
  background-color: #09a6d1;
  right: -0.3vw;
  border-radius: 1vw;
  width: 9vw;
  transition: right 0.8s;
  height: 5vh;
}

.aSide2Activo {
  display: flex;
  position: absolute;
  margin-bottom: 10%;
  background-color: #09a6d1;
  right: -0.3vw;
  border-radius: 1vw;
  width: 9vw;
  transition: right 0.8s;
  height: 5vh;
}

.aSide3 {
  display: flex;
  position: absolute;
  margin-bottom: 10%;
  background-color: #09a6d1;
  right: -0.3vw;
  border-radius: 1vw;
  width: 9vw;
  transition: right 0.8s;
  height: 5vh;
}

.aSide3Activo {
  display: flex;
  position: absolute;
  margin-bottom: 10%;
  background-color: #09a6d1;
  right: -0.3vw;
  border-radius: 1vw;
  width: 9vw;
  transition: right 0.8s;
  height: 5vh;
}

.aSide4 {
  display: flex;
  position: absolute;
  margin-bottom: 10%;
  background-color: #09a6d1;
  right: -0.3vw;
  border-radius: 1vw;
  width: 9vw;
  transition: right 0.8s;
  height: 5vh;
}

.aSide4Activo {
  display: flex;
  position: absolute;
  margin-bottom: 10%;
  background-color: #09a6d1;
  right: -0.3vw;
  border-radius: 1vw;
  width: 9vw;
  transition: right 0.8s;
  height: 5vh;
}

.aSide5 {
  display: flex;
  position: absolute;
  margin-bottom: 10%;
  background-color: #09a6d1;
  right: -0.3vw;
  border-radius: 1vw;
  width: 9vw;
  transition: right 0.8s;
  height: 5vh;
}

.aSide5Activo {
  display: flex;
  position: absolute;
  margin-bottom: 10%;
  background-color: #09a6d1;
  right: -0.3vw;
  border-radius: 1vw;
  width: 9vw;
  transition: right 0.8s;
  height: 5vh;
}

.aSide6 {
  display: flex;
  position: absolute;
  margin-bottom: 10%;
  background-color: #09a6d1;
  right: -0.3vw;
  border-radius: 1vw;
  width: 9vw;
  transition: right 0.8s;
  height: 5vh;
}

.aSide6Activo {
  display: flex;
  position: absolute;
  margin-bottom: 10%;
  background-color: #09a6d1;
  right: -0.3vw;
  border-radius: 1vw;
  width: 9vw;
  transition: right 0.8s;
  height: 5vh;
}

.aSide7 {
  display: flex;
  position: absolute;
  margin-bottom: 10%;
  background-color: #09a6d1;
  right: -0.3vw;
  border-radius: 1vw;
  width: 9vw;
  transition: right 0.8s;
  height: 5vh;
}

.aSide7Activo {
  display: flex;
  position: absolute;
  margin-bottom: 10%;
  background-color: #09a6d1;
  right: -0.3vw;
  border-radius: 1vw;
  width: 9vw;
  transition: right 0.8s;
  height: 5vh;
}

.aSide11 {
  display: none;
}
.aSide12 {
  display: initial;
}

.aSide21 {
  display: flex;
  position: absolute;
  margin-bottom: 10%;
  background-color: #09a6d1;
  right: -0.3vw;
  border-radius: 1vw;
  width: 9vw;
  transition: right 0.8s;
  height: 5vh;
  margin-top: 11vh;
}
.aSide31 {
  display: flex;
  position: absolute;
  margin-bottom: 10%;
  background-color: #09a6d1;
  right: -0.3vw;
  border-radius: 1vw;
  width: 9vw;
  transition: right 0.8s;
  height: 5vh;
  margin-top: 11vh;
}
.aSide41 {
  display: flex;
  position: absolute;
  margin-bottom: 10%;
  background-color: #09a6d1;
  right: -0.3vw;
  border-radius: 1vw;
  width: 9vw;
  transition: right 0.8s;
  height: 5vh;
  margin-top: 11vh;
}
.aSide51 {
  display: flex;
  position: absolute;
  margin-bottom: 10%;
  background-color: #09a6d1;
  right: -0.3vw;
  border-radius: 1vw;
  width: 9vw;
  transition: right 0.8s;
  height: 5vh;
  margin-top: 11vh;
}
.aSide61 {
  display: flex;
  position: absolute;
  margin-bottom: 10%;
  background-color: #09a6d1;
  right: -0.3vw;
  border-radius: 1vw;
  width: 9vw;
  transition: right 0.8s;
  height: 5vh;
  margin-top: 11vh;
}
.aSide71 {
  display: flex;
  position: absolute;
  margin-bottom: 10%;
  background-color: #09a6d1;
  right: -0.3vw;
  border-radius: 1vw;
  width: 9vw;
  transition: right 0.8s;
  height: 5vh;
  margin-top: 11vh;
}

.Menu1 {
  margin-bottom: 3%;
  border-radius: 9px;
  justify-content: flex-end;
  display: flex;
}

.liMenu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 9px;

  transition: right 1.3s;
}

.liMenu button {
  border-bottom: 1px solid white;
  border-radius: 3px;
  color: white;
  margin-bottom: 2px;
  background-color: #09a6d1;
  height: 24px;
  width: 169px;
  font-size: 13px;
}

.liMenu button:hover {
  background-color: #03718f;
}

.liMenu2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 9px;
}

.liMenu2 button {
  border-bottom: 1px solid white;
  border-radius: 3px;
  color: white;
  margin-bottom: 2px;
  background-color: #09a6d1;
  height: 24px;
  width: 169px;
  font-size: 13px;
}

.liMenu2 button:hover {
  background-color: #03718f;
}

@media (max-width: 1450px) {
  .divPrincipal {
    position: fixed;
    top: 17%;
    right: -126px;
    background-color: transparent;
    height: 70%;
    width: 8%;
    z-index: 42;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .sideBar_liMenu_button {
    height: 24px !important;
    width: 169px !important;
    font-size: 13px !important;
  }

  .liMenu {
    margin-right: 124px;
  }

  .liMenu2 {
    margin-right: 124px;
  }

  .selectSideGrande {
    font-size: 75%;
  }

  .liside {
    height: 6vh;
  }

  .liside a {
    height: 5.5vh;
  }

  .selectSideGrande1 {
    font-size: 78%;
  }

  .selectSide {
    font-size: 78%;
  }

  .imgNotificacion {
    width: 6vh;
    height: 6vh;
  }

  .imgConfig {
    width: 6vh;
    height: 6vh;
  }

  .imgCesfam {
    width: 6vh;
    height: 6vh;
  }
}

@media (max-width: 1285px) {
  .selectSideGrande {
    font-size: 78%;
  }

  .selectSideGrande1 {
    font-size: 78%;
  }

  .selectSide {
    font-size: 78%;
  }
}

@media (max-height: 550px) {
  .selectSideGrande {
    font-size: 10px;
  }

  .selectSideGrande1 {
    font-size: 10px;
  }

  .liMenu {
    margin-right: 124px;
  }

  .liMenu2 {
    margin-right: 124px;
  }
}
