.cesfam {
  background-color: #1691c1;
  height: 40px;
  width: 300px;
  margin: 300px 0 20px 20px;
  display: flex;
  right: 0;
  border-radius: 40px 0 0 40px;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  z-index: 25;
}
.menu {
  background-color: #1691c1;
  height: 40px;
  width: 50px;
  margin: 350px 0 20px 20px;
  display: flex;
  right: 0;
  border-radius: 40px 0 0 40px;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  z-index: 25;
  top: -7vw;
}

.notificacion {
  background-color: #1691c1;
  height: 40px;
  width: 50px;
  margin: 400px 0 20px 20px;
  display: flex;
  right: 0;
  border-radius: 40px 0 0 40px;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  z-index: 25;
  top: -7vw;
}

.perfil {
  background-color: #1691c1;
  height: 40px;
  width: 50px;
  margin: 450px 0 20px 20px;
  display: flex;
  right: 0;
  border-radius: 40px 0 0 40px;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  z-index: 25;
  top: -7vw;
}

.menu .sub-menu,
.notificacion .sub-notificacion,
.perfil .sub-perfil {
  z-index: 24;
}
.menu .panel-menu,
.notificacion .panel-notificacion,
.perfil .panel-perfil {
  z-index: 30;
}

.menu-bars {
  padding-left: 0.8rem;
  padding-top: 0.3rem;
  font-size: 2rem;
  background: none;
}

.menu-bars-cesfam {
  padding-left: 0.8rem;
  padding-top: 0.3rem;
  font-size: 2rem;
  background: none;
  position: fixed;
}

.nav-menu {
  background-color: #1691c1;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: -100%;
  transition: 2050ms;
  z-index: 25;
}

.nav-menu-cesfam {
  background-color: #1691c1;
  width: 250px;
  height: 40px;
  display: flex;
  position: fixed;
  top: 0px;
  right: -200px;
  transition: 850ms;
  z-index: 25;
  top: -7vw;
}

.nav-menu.active,
.nav-menu-cesfam.active {
  right: 0;
  transition: 850ms;
}


.nav-menu-cesfam2 {
  background-color: #1691c1;
  width: 250px;
  height: 40px;
  display: flex;
  position: fixed;
  top: 0px;
  right: -332px;
  transition: 850ms;
  z-index: 25;
  top: -7vw;
}


.nav-menu-cesfam2.active {
  right: 0;
  transition: 850ms;
}


.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px 8px 0px;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-weight: bold;
  font-size: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #0f7096;
}

.nav-menu-items {
  width: 100%;
  overflow-y: scroll;
}

.navbar-toggle {
  background-color: #69b3da;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.nav-submenu span,
.nav-menu span {
  margin-left: 16px;
}

.nav-submenu {
  background-color: #69b3da;
  width: 250px;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: -200%;
  transition: 850ms;
  z-index: 40;
}

.nav-submenu.active {
  right: 250px;
  transition: 350ms;
}

.menu-titulo {
  padding: 13px 0px 13px 0px;
  height: 60px;
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
}

.nav-notificacion {
  text-decoration: none;
  color: #fff;
  margin: 8px 10% 0 10%;
  width: 80%;
  border: white 2px solid;
  font-size: 0.9rem;
  text-decoration: none;

  border-radius: 4px;
  list-style-type: none;
}

.nav-notificacion > p {
  padding-right: 10px;
  width: 100%;
  text-align: right;
  cursor: pointer;
}

.nav-notificacion > a > div {
  padding: 8px;
  width: 100%;
  cursor: pointer;
}

.menu-icono-perfil {
  width: 100%;
  font-size: 7rem;
  text-align: center;
}

.menu-bienvenido {
  padding: 0px 0px 0px 0px;
  color: #fff;
  font-size: 1.1rem;
  font-weight: bold;
  text-align: center;
}

.menu-nombre {
  padding: 0px 0px 13px 0px;
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  text-align: center;
}

.opcion-perfil > a > span {
  text-align: left;
  width: 100%;
}

.marca-de-agua {
  background-image: url("./../img/marca_de_agua20.svg");
  background-repeat: no-repeat;
  background-position: right bottom;
}

.cerrar-notificacion {
  border: 1px solid #1691c1;
  color: #1691c1;
  border-radius: 15px;
  padding: 0px 5px 2px 6px;
  margin-top: -8px;
  position: absolute;
  background-color: white;
}

.cerrar-notificacion:hover {
  background-color: #8ac9ec;
}

.desplegar-notificacion {
  float: right;
  font-size: 13px;
  border: 1px solid white;
  border-radius: 15px;
  padding: 0px 4px 0px 4px;
  margin-right: 6px;
  margin-top: 5px;
}
