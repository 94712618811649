.titulo_formulario {
  font-size: 1.3em;
  color: rgba(0, 0, 0, 0.87);
}

.capital {
  text-transform: capitalize !important;
}

.formDivProfesional {
  padding: 21px;
  border-radius: 5px;
  float: left;
}

.formDivProfesional .MuiOutlinedInput-input {
  width: 250px !important;
  padding: 8.5px 14px !important;
}

.selectProfesionales .MuiOutlinedInput-input {
  width: 150px !important;
}

.textoDatos {
  color: #fff;
  font-size: 16px;
}

.btones {
  color: #000 !important;
  background-color: #fff0;
  border-radius: 12px !important;
  border-color: #000;
  margin-bottom: 5px;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid #000;
  border-radius: 4px;
  width: 38px;
  height: 26px;
}

.btonesDisabled {
  color: #000 !important;
  background-color: #9c9c9c;
  border-radius: 12px !important;
  border-color: #9c9c9c;
  margin-bottom: 5px;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid #9c9c9c;
  border-radius: 4px;
  width: 38px;
}

.btonesAccion {
  width: 147px;
  margin-top: 8px !important;
  margin-right: 8px !important;
}

.buscarProfesional .MuiButton-contained,
.modificarProfesional .MuiButton-contained {
  box-shadow: none !important;
  color: #fff !important;
  width: auto !important;
  text-transform: capitalize !important;
}

.modificarProfesional input {
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  height: 1.1876em;
  margin: 0;
  display: block;
  padding: 6px 0 7px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
  border-bottom: 1px solid #000;
}

.modificarProfesional input:focus {
  outline: none;
}

.horasProfesional .MuiButton-contained {
  box-shadow: none !important;
  color: #fff !important;
}

.formDivProfesional {
  max-width: 100%;
}

.formDivProfesional #tablahorarioProfesional {
  overflow-x: scroll;
}

.modificarProfesional .divBotones {
  margin-top: 40px;
}

.modificarProfesional .MuiButton-textPrimary {
  color: #fff !important;
  float: right !important;
  margin-left: 10px;
}

.modificarProfesional input[type="checkbox"] {
  width: auto !important;
}

.modificarProfesional .dropdown-content {
  z-index: 1000 !important;
}

.horaCargada {
  background-color: rgb(38, 165, 48);
  padding: 3px 5px;
  border-radius: 5px;
}

.horaBloqueada {
  background-color: #444444;
  padding: 3px 5px;
  border-radius: 5px;
}
.botonesHorarioDiario button {
  float: right;
  margin-right: 10px;
}

.selectMotivoBloqueo {
  float: right;

  align-content: right;
}

.tdHorario {
  border: black 1px solid;
}

.modalCargarHorario .MuiDialog-paperWidthSm,
.horarioDiarioProfesional .MuiDialog-paperWidthSm,
.modalAgregarProfesional .MuiDialog-paperWidthSm,
.modalModificarProfesional .MuiDialog-paperWidthSm {
  background-image: url("./../img/marca_de_agua20.svg");
  background-repeat: no-repeat;
  background-position: right bottom;
}

.horarioDiarioProfesional .MuiPaper-elevation1 {
  box-shadow: inherit;
}

.horarioDiarioProfesional .MuiDialog-paperWidthSm {
  max-width: 90%;
}

.modalCargarHorario .MuiTable-root {
  width: 68% !important;
  margin: auto !important;
}

.modalCargarHorario .MuiPaper-elevation1 {
  box-shadow: inherit !important;
}

.porSeleccionar {
  border: 1px black solid;
  padding: 5px 15px !important;
  border-radius: 15px !important;
  cursor: pointer;
}

.btnCargaHorario {
  margin-left: 10px !important;
  margin-top: 20px !important;
  box-shadow: inherit !important;
  color: #fff !important;
  float: right !important;
}

.MuiButton-label {
  text-transform: capitalize !important;
}

.imagenBotonAccion {
  width: 40px;
  margin-right: 7px;
  margin-bottom: -10px;
  float: right;
  cursor: pointer;
}

.imagenDatoSeleccionadoProfesional {
  width: 55px;
  margin-top: 15px;
}

.datosProfesional {
  margin-top: -45px;
  border-radius: 5px;
  border: #eaa028 2px solid;
  margin-left: 52px;
}

.menuSeleccionado {
  width: 80px !important;
  margin-top: -7px !important;
}

.botonDescargarHorario {
  max-width: 160px;
  float: right;
  margin: 0px 20px !important;
  height: 5vh;
}

@media screen and (max-width: 40em) {
  #vistaProfesionalAcciones {
    margin-top: -10px !important;
    margin-bottom: 30px;
  }
  #vistaProfesionalEsp {
    margin-top: -35px !important;
  }

  #vistaProfesionalEsp .MuiOutlinedInput-root {
    width: 170px;
  }

  #vistaProfesionalBtnAgregar {
    margin-top: -30px !important;
  }

  #vistaProfesionalFiltrosHorarios {
    margin-top: 20px;
  }
}

@media screen and (max-width: 60em) and (min-width: 40em) {
  #vistaProfesionalEsp {
    margin-top: -40px !important;
  }

  #vistaProfesionalAcciones {
    margin-top: 0px !important;
    margin-bottom: 30px;
  }
}

.buscarProfesional .MuiGrid-grid-sm-8 {
}

#tablahorarioProfesional .MuiTableContainer-root {
  background-color: rgb(232 236 239 / 20%) !important;
}

.btnDupRenHorario {
  margin-left: 10px !important;
  box-shadow: inherit !important;
  color: #fff !important;
  float: right !important;
}
