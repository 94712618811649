.imgCMciclos {
  margin-left: 0%;
  width: 7%;
  margin-right: 0%;
  margin-top: 0%;
  cursor: pointer;
}
.imgCMciclos1 {
  margin-left: 0%;
  width: 6%;
  margin-right: 0%;
  margin-top: 0%;
  cursor: pointer;
}

.imgCMbeneficio {
  margin-left: 2%;
  width: 6%;
  margin-right: 0%;
  margin-top: 0%;
  cursor: pointer;
}
.imgCMbeneficio1 {
  margin-left: 2%;
  width: 7%;
  margin-right: 0%;
  margin-top: 0%;
  cursor: pointer;
}

.imgCMfallecido {
  margin-left: 2%;
  width: 6%;
  margin-right: 0%;
  margin-top: 0%;
  cursor: pointer;
}
.imgCMfallecido1 {
  margin-left: 2%;
  width: 7%;
  margin-right: 0%;
  margin-top: 0%;
  cursor: pointer;
}

.imgCMprematuro {
  margin-left: 2%;
  width: 6%;
  margin-right: 0%;
  margin-top: 0%;
  cursor: pointer;
}
.imgCMprematuro1 {
  margin-left: 2%;
  width: 7%;
  margin-right: 0%;
  margin-top: 0%;
  cursor: pointer;
}

.imgCMreservaExterna {
  margin-left: 2%;
  width: 6%;
  margin-right: 0%;
  margin-top: 0%;
  cursor: pointer;
}
.imgCMreservaExterna1 {
  margin-left: 2%;
  width: 7%;
  margin-right: 0%;
  margin-top: 0%;
  cursor: pointer;
}

.divIcon {
  display: flex;
  align-items: center;
}

.selectCiclos {
  border: 2px solid #eaa028;
  border-radius: 0.6vw;
  width: 16vw;
  height: 4vh;
}

.selectCiclos path {
  fill: #eaa028;
}

.selectCiclos::before {
  border: none !important;
}

.divSelectIncribir {
  display: flex;
  float: left;
  margin-left: 20px;
  flex-direction: column;
}

.labelCiclos {
  font-size: 1.4vw !important;
  color: black !important;
}

.imgEjemploCiclo {
  position: absolute;
  width: 40%;
  right: 12%;
  top: 25%;
  border: 2px solid #eaa028;
  border-radius: 1vw;
}

.csvCargaMasiva {
  width: max-content;
}

.csvCargaMasiva div {
  border: none !important;
}

.spanArchivo {
  background-color: #eaa028;
  margin-right: 3px;
  border-radius: 1vw;
  height: 1.2vw;
  color: white;
  width: 10vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9vw;
}

.divSpanArchivo {
  border: 2px solid #eaa028;
  display: flex;
}

.csvCargaMasiva div div {
  border: 2px solid #eaa028 !important;
  padding: 3px 3px 3px 3px;
  border-radius: 6px;
  width: 100% !important;
}

.csvCargaMasiva div div div {
  border: none !important;
  border-radius: 6px;
}
